import $ from "../../global/util";

export default {
  LOGIN: {
    PATH: "/users/sign_in.json",
    API_PARAMS: {
      USER_NAME: "login",
      PASSWORD: "password",
      APP_ID: "app_id",
      MERCHANT_ID: "merchant_id",
    },
  },
  SIGNUP: {
    PATH: "/users.json",
    API_PARAMS: {
      USER_NAME: "name",
      PASSWORD: "password",
      EMAIL: "email",
      PHONE_NUMBER: "phone_number",
      ADDRESS: "address",
      MERCHANT_ID: "merchant_id",
      PROFILE_PIC: "profile_pic",
    },
  },
  SIGNUP_MARKET_PLACE: {
    PATH: "/marketplace/user/v1/users/sign_up.json",
    API_PARAMS: {
      USER_NAME: "name",
      PASSWORD: "password",
      EMAIL: "email",
      PHONE_NUMBER: "phone_number",
      ADDRESS: "address",
      MERCHANT_ID: "merchant_id",
      PROFILE_PIC: "profile_pic",
    },
  },
  FORGOT_PASSWORD: {
    PATH: "/users/reset_password.json",
    API_PARAMS: {
      USER: "user",
      APP_ID: "app_id",
      MERCHANT_ID: "merchant_id",
      LOGIN: "login",
    },
  },
  RESET_PASSWORD: {
    PATH: "/users/set_password.json",
    API_PARAMS: {
      USER: "user",
      APP_ID: "app_id",
      MERCHANT_ID: "merchant_id",
      LOGIN: "login",
      PASSWORD: "password",
      TOKEN: "token",
    },
  },
  SOCIAL_LOGIN: {
    PATH: "",
    API_PARAMS: {
      USER: "user",
      TOKEN: "token",
      LOGIN: "login",
      PASSWORD: "password",
      MERCHANT_ID: "merchant_id",
      APP_ID: "app_id",
    },
  },
  LOGOUT: {
    PATH: "/users/sign_out.json",
  },
  LOGIN_STATUS: {
    PATH: "/me.json",
  },
  OPERATOR_STATUS: {
    PATH: "/operator/v1/me.json",
  },
};
