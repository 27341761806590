import React, { useContext, useReducer, useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import SearchableScreen from "../SearchableScreen/SearchableScreen";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import UserContext from "@presto-contexts/UserContext";

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_TOP_CATEGORIES":
      return { ...state, top_level_categories: payload };

    case "SET_CATEGORY_COUNT":
      return {
        ...state,
        category_count: {
          ...state.category_count,
          ...{ [payload.cat_id]: payload.count },
        },
      };
  }
  return state;
}

const windowHeight = Dimensions.get("window").height;

export default function Browse({ navigation, route }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [state, dispatch] = useReducer(reducer, { category_count: {} });
  const { category, catalogId } = route.params;
  const { user } = useContext(UserContext);

  useEffect(() => {
    SearchManager.searchCategory(
      { category_id: category.id },
      (response) => {
        dispatch({
          type: "SET_TOP_CATEGORIES",
          payload: response.hits.hits,
        });
      },
      (error) => { }
    );
  }, []);

  useEffect(() => {
    _.forEach(state.top_level_categories, (cat) => {
      let params = {
        category_id: cat._source.id,
      };
      if (user?.other_data?.medicine == 0) {
        params.medicine = user?.other_data?.medicine;
      }
      SearchManager.fuzzySearchCategoryItem(
        params,
        (response) => {
          dispatch({
            type: "SET_CATEGORY_COUNT",
            payload: { cat_id: cat._source.id, count: response.hits.total?.value || response.hits.total },
          });
        },
        (error) => { }
      );
    });
  }, [state.top_level_categories]);

  const onCategoryPress = (cat) => {
    console.log("Cat Id : ", cat);
    SearchManager.searchCategory(
      { category_id: cat._id },
      (response) => {
        if (response.hits.hits.length === 0) {
          Navigator.emit({
            event: "push",
            params: {
              screenName: "SEARCH_RESULTS_SCREEN",
              screenParams: {
                searchParams: { category: cat._source, catalog_id: catalogId },
              },
            },
          });
        } else {
          Navigator.emit({
            event: "push",
            params: {
              screenName: "SUB_CATEGORIES_BROWSE_SCREEN",
              screenParams: {
                category: cat._source,
                searchParams: { category: cat._source },
              },
            },
          });
        }
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  };

  const onBack = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  const onSearchFocus = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: { searchParams: {}, focus: true, catalog_id: catalogId },
      },
    });
  };

  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      canGoback={true}
      onBack={onBack}
      showSoftInputOnFocus={false}
      placeholder={I18n.t("screen_messages.common.search_with_query", {
        query: category.name,
      })}
    >
      <ScrollView
        style={{ height: windowHeight - 100 }}
        contentContainerStyle={{ paddingBottom: 50 }}
      >
        <>
          {_.map(state.top_level_categories, (cat) => {
            return (
              <View key={cat._id}>
                <RowButton
                  title={cat._source.name}
                  rightTitle={state.category_count[cat._source.id]}
                  onPress={() => {
                    onCategoryPress(cat);
                  }}
                  showDisclosure={true}
                  numberOfLines={2}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </View>
            );
          })}
        </>
        <VerticalSpacing size={30} />
        <DealsComponent />
        <VerticalSpacing size={40} />
      </ScrollView>
    </SearchableScreen>
  );
}
