import React from "react";
import _ from "lodash";
import { View } from "react-native";
import I18n from "i18n-js";
import { useActionSheet } from "@expo/react-native-action-sheet";
import * as Permission from "expo-permissions";
// import ImagePicker from "react-native-image-crop-picker";
import ImagePicker from "@presto-common/ImagePicker";
import config from "@presto-common/config";
import { alertBox } from "@presto-common/Alert";

export default function MediaLibrary({
  showOptions = false,
  callback,
  onShowOptions,
  cameraQuality = 1,
  showOption = false,
  fileIndex,
}) {
  const { showActionSheetWithOptions } = useActionSheet();
  const cameraOptions = {
    mediaType: "photo",
    quality: cameraQuality,
    maxWidth: 0,
    maxHeight: 0,
    includeBase64: true,
    cameraType: "back",
    selectionLimit: 1,
    saveToPhotos: false,
    durationLimit: 0,
    includeExtra: false,
    presentationStyle: "pageSheet",
  };

  const openCamera = async () => {
    const { status } = await Permission.askAsync(Permission.CAMERA);
    if (status === "granted") {
      ImagePicker.openCamera({
        compressImageQuality: cameraQuality,
        multiple: true,
        includeBase64: true,
        cropping: config.features.enable_cropping,
        freeStyleCropEnabled: config.features.enable_cropping,
      })
        .then((images) => {
          const allowedSizeInBytes = 1000000;
          if (images && images?.size > allowedSizeInBytes) {
            alertBox("", I18n.t("screen_messages.files.alert1"));
            callback(null);
          } else {
            callback([images]);
          }
        })
        .catch((error) => {
          console.log(`MediaLibrary openCamera error`, error);
          callback(null);
        });
    }
  };

  const openFileManager = async () => {
    const { status } = await Permission.askAsync(Permission.CAMERA);
    if (status === "granted") {
      ImagePicker.openPicker({
        multiple: true,
        includeBase64: true,
        cropping: true,
        showsSelectedCount: true,
        sortOrder: "desc",
        compressImageQuality: cameraQuality,
      })
        .then((images) => {
          callback(images);
        })
        .catch((error) => {
          console.log(`MediaLibrary openFileManager error`, error);
          callback(null);
        });
    }
  };

  const showActionSheet = () => {
    onShowOptions(false);
    let buttonIndex = fileIndex || 0;
    if (showOption) {
      showActionSheetWithOptions(
        {
          options: [
            I18n.t("screen_messages.common.open_camera"),
            I18n.t("screen_messages.common.open_file_manager"),
            I18n.t("screen_messages.common.cancel_text"),
          ],
          message: I18n.t("screen_messages.common.choose_file"),
          cancelButtonIndex: 2,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              openCamera();
              break;

            case 1:
              openFileManager();
              break;

            default:
              break;
          }
        }
      );
    } else {
      switch (buttonIndex) {
        case 0:
          openCamera();
          break;
        case 1:
          openFileManager();
          break;
        default:
          break;
      }
    }
  };

  if (showOptions) {
    showActionSheet();
  }

  return <View />;
}
