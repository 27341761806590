import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useReducer,
} from "react";
import { Picker } from "@react-native-picker/picker";
import {
  View,
  Image,
  Platform,
  Keyboard,
  Alert,
  Text,
  StyleSheet,
  Switch,
  TouchableWithoutFeedback,
} from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import asyncify from "../../common/Asyncify";
import utils from "../../utils/index";
import SessionManager from "@presto-services/features/session/SessionManager";
const AsyncSessionManager = asyncify(SessionManager);
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoText from "@presto-components/PrestoText";
import DocumentManager from "@presto-services/features/documents/DocumentManager";
import {
  PrestoFramedButton,
  PrestoSolidButton,
} from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";
import TopHeaderWithTitle from "@presto-components/Headers/TopHeaderWithTitle";
import ImageUploadButton from "@presto-screen-components/ImageUpload/ImageUploadButton.zipHealth";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import Utils from "@presto-services/common/Utils";
import * as Linking from "expo-linking";
import ImageUploadDelivery from "@presto-screen-components/ImageUpload/ImageUploadDelivery";
import UserObject from "@presto-services/data_models/UserObject";
import ProfileManager from "@presto-services/features/profile/ProfileManager";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import MessagesContext from "@presto-contexts/MessagesContext";
import Config, { updateConfig } from "@presto-common/config";
import DropDownPicker from "react-native-dropdown-picker";

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_LOADING":
      return { ...state, loading: payload };
    case "SET_IMAGES":
      return { ...state, images: payload };
    case "SET_ALL_IMAGES":
      return { ...state, allImages: payload };
    case "SET_LICENSE":
      return { ...state, license: payload };
    case "SET_CERTIFICATE":
      return { ...state, certificate: payload };
    case "SET_UPLOADED_LICENSES":
      return { ...state, allLicenseDocuments: payload };
    case "SET_UPLOADED_CERTIFICATES":
      return { ...state, allCertificateDocuments: payload };
  }
}

export default function RegisterScreen(props) {
  const AsyncProfileManager = asyncify(ProfileManager);
  const { title, shouldGoBack, goBack, documentError } =
    props.route.params || {};
  const { images: Images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { user, setUser, refreshUser, setCurrentAddress } = useContext(
    UserContext
  );
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [namePharma, setNamePharma] = useState("");
  const [TPIN, setTPIN] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [refer, setRefer] = useState("");
  const [email, setEmail] = useState("");
  const [zamraNumber, setZamraNumber] = useState("");
  const [padding, setPadding] = useState(0);
  const { Navigator } = useContext(NavigatorContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [userProfile, setUserProfile] = useState();
  const [label, setSelectedLabel] = useState("");
  const [open, setOpen] = useState(false);

  const { locale, setLanguage } = useContext(MessagesContext);
  const [language, setScreenLanguage] = useState(locale);
  useEffect(() => {
    setScreenLanguage(locale);
  }, [locale]);

  const toggleNetwork = () => {
    if (locale === "en-US") {
      setLanguage("en-Portugese");
    } else {
      setLanguage("en-US");
    }
  };

  const [pharmacy, setPharmancy] = useState([]);

  useEffect(() => {
    setPharmancy(
      Config?.applicationName === "Zip Health Angola"
        ? [
            {
              label: I18n.t("screen_messages.register.pharmacy_text"),
              value: "Pharmacy",
            },
            {
              label: I18n.t("screen_messages.register.medical_center_text"),
              value: "Medical Center",
            },
            {
              label: I18n.t("screen_messages.register.general_store_text"),
              value: "General Stores",
            },
          ]
        : [
            {
              label: I18n.t("screen_messages.register.pharmacy_text"),
              value: "Pharmacy",
            },
            {
              label: I18n.t("screen_messages.register.healthshop_text"),
              value: "Healthshop",
            },
            {
              label: I18n.t("screen_messages.register.general_store_text"),
              value: "General Stores",
            },
          ]
    );
  }, [language]);

  const locationInfoRef = useRef({
    latitude: null,
    longitude: null,
  });

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    license: [],
    certificate: [],
  });

  const { license, certificate } = state;

  const getUserProfile = () => {
    setLoading(true);
    ProfileManager.getMyProfile(
      {},
      (response) => {
        console.log(response, "response===============");
        setUserProfile(response?.data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log("User Profile error : ", error);
        alert(I18n.t("screen_messages.try_again_later"));
      }
    );
  };

  useEffect(() => {
    if (user !== null) {
      getUserProfile();
    }
  }, []);

  console.log(user, userProfile, "user====================");
  console.log(locale, "user====================");

  const onTPINChange = ({ nativeEvent: { text } }) => {
    setTPIN(text);
  };

  const onEmailChange = ({ nativeEvent: { text } }) => {
    setEmail(text);
  };

  const onZamraNumberChange = ({ nativeEvent: { text } }) => {
    setZamraNumber(text);
  };

  const onAddressChange = ({ nativeEvent: { text } }) => {
    setAddress(text);
  };

  const onCityChange = ({ nativeEvent: { text } }) => {
    setCity(text);
  };

  const onReferChange = ({ nativeEvent: { text } }) => {
    setRefer(text);
  };

  const onPhoneNumberChange = ({ nativeEvent: { text } }) => {
    setPhoneNumber(text);
  };

  const onNameChange = ({ nativeEvent: { text } }) => {
    setName(text);
  };

  const onPharmaNameChange = ({ nativeEvent: { text } }) => {
    setNamePharma(text);
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      console.log("in showSubscription");
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      console.log("in hidesubscription");
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  function getDocumentIds(data1, data2) {
    const combinedData = [...data1, ...data2];
    const documentIds = combinedData.map((item) => item.id);
    return documentIds.join(",");
  }

  const onUpdateProfile = async (
    user,
    licenseResponse,
    certificateResponse
  ) => {
    setLoading(true);
    const documentIds = getDocumentIds(licenseResponse, certificateResponse);
    let userParams = {
      user_profile: {
        document_ids: documentIds,
        gst_in: userProfile ? userProfile?.gst_in : TPIN,
        type_of_company: userProfile
          ? userProfile?.type_of_company
          : selectedOption,
      },
    };
    console.log("update params===", userParams);
    const [error, response] = await AsyncProfileManager.updateProfileDocument(
      userParams
    );
    if (response && response.data) {
      setLoading(false);
    } else {
      setLoading(false);
      alert(error.message);
    }
  };

  const uploadDocuments = async (user) => {
    let array = license;
    let array2 = certificate;
    let licenseResponse = [];
    let certificateResponse = [];

    if (!array.length && !array2.length) {
      await onUpdateProfile(user);
      return;
    }

    setLoading(true);

    const data1 = new FormData();
    if (array.length) {
      data1.append("document[uploaded_file]", {
        uri: array[array.length - 1]?.document_url,
        name: array[array.length - 1]?.document_title,
        type: array[array.length - 1]?.document_type,
      });
      data1.append("document[name]", array[array.length - 1]?.document_title);
      data1.append(
        "document[type]",
        Config?.applicationName === "Zip Health Angola"
          ? "License ARMED"
          : "License ZAMRA"
      );
      data1.append(
        "document[number]",
        Config?.applicationName === "Zip Health Zambia"
          ? zamraNumber
          : `${locationInfoRef.current.latitude},${locationInfoRef.current.longitude}`
      );
    }

    const data2 = new FormData();
    if (array2.length) {
      data2.append("document[uploaded_file]", {
        uri: array2[array2.length - 1]?.document_url,
        name: array2[array2.length - 1]?.document_title,
        type: array2[array2.length - 1]?.document_type,
      });
      data2.append("document[name]", array2[array2.length - 1]?.document_title);
      data2.append(
        "document[type]",
        Config?.applicationName === "Zip Health Angola"
          ? "Alvara Certificate"
          : "PACRA/Pharmacist Certificate"
      );
      data2.append(
        "document[number]",
        `${locationInfoRef.current.latitude},${locationInfoRef.current.longitude}`
      );
    }
    console.log("license params===", data1);
    const licenseUpload = array.length
      ? new Promise((resolve, reject) => {
          DocumentManager.createDocument(
            data1,
            (response) => {
              console.log("License uploaded:", response);
              if (response.data) {
                licenseResponse.push(response.data);
              }
              resolve();
            },
            (error) => {
              console.log("Error uploading license:", error);
              reject(error);
            }
          );
        })
      : Promise.resolve();

    console.log("license params===", data2);
    const certificateUpload = array2.length
      ? new Promise((resolve, reject) => {
          DocumentManager.createDocument(
            data2,
            (response) => {
              console.log("Certificate uploaded:", response);
              if (response.data) {
                certificateResponse.push(response.data);
              }
              resolve();
            },
            (error) => {
              console.log("Error uploading certificate:", error);
              reject(error);
            }
          );
        })
      : Promise.resolve();

    try {
      await Promise.all([licenseUpload, certificateUpload]);
      console.log("All uploads completed successfully");
      await onUpdateProfile(user, licenseResponse, certificateResponse);
    } catch (error) {
      console.log("Error in uploading documents:", error);
    } finally {
      setLoading(false);
    }
  };

  const ConfirmAlert = () => {
    Alert.alert(
      "Registration Successful",
      "Thank you for registering! We will contact you very soon!",
      [
        {
          text: "Continue",
          onPress: () => {
            setTimeout(() => {
              Navigator.emit({ event: "goBack" });
            }, 100);
          },
        },
      ],
      { cancelable: false }
    );
  };

  function generateRandomText(prefix = "test", length = 5) {
    const characters = "0123456789abcdefghijklmnopqrstuvwxyz";
    let randomText = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomText += characters[randomIndex];
    }

    return `${prefix}${randomText}`;
  }

  const reUploadDocument = async () => {
    if (
      Config?.applicationName === "Zip Health Zambia" &&
      zamraNumber.length == 0
    ) {
      alert(I18n.t("screen_messages.register.zamra_number_error"));
      return;
    }
    if (license.length == 0) {
      alert(I18n.t("screen_messages.register.license_error"));
      return;
    }
    if (certificate.length == 0) {
      alert(I18n.t("screen_messages.register.certificate_error"));
      return;
    }
    setLoading(true);
    await uploadDocuments();
    setLoading(false);
    refreshUser(user);
    goBack();
  };

  const updateAddress = async (user, addressObj) => {
    setLoading(true);
    // setCurrentAddress(addressObj);
    console.log("address params===", addressObj);
    ProfileManager.addAddress(
      { address: addressObj, user_id: user.id },
      (response) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log("Add error : ", error);
        alert(I18n.t("screen_messages.try_again_later"));
      }
    );
  };

  const validateFields = () => {
    if (phoneNumber.length == 0) {
      alert(I18n.t("screen_messages.register.mobile_number_error"));
      return true;
    } else if (namePharma.length == 0) {
      alert(I18n.t("screen_messages.register.pharmacy_name_error"));
      return true;
    } else if (selectedOption.length == 0) {
      alert(I18n.t("screen_messages.register.pharmacy_type_error"));
      return true;
    } else if (name.length == 0) {
      alert(I18n.t("screen_messages.register.name_error"));
      return true;
    } else if (TPIN.length == 0) {
      alert(
        Config?.applicationName === "Zip Health Angola"
          ? I18n.t("screen_messages.register.nif_error")
          : I18n.t("screen_messages.register.tpin_error")
      );
      return true;
    } else if (address.length == 0) {
      alert(I18n.t("screen_messages.register.address_error"));
      return true;
    } else if (city.length == 0) {
      alert(I18n.t("screen_messages.register.city_error"));
      return true;
    } else if (
      Config?.applicationName === "Zip Health Zambia" &&
      zamraNumber.length == 0
    ) {
      alert(I18n.t("screen_messages.register.zamra_number_error"));
      return true;
    }
    return false;
  };

  const onSignup = () => {
    if (validateFields()) {
      return;
    }
    if (license.length == 0) {
      alert(I18n.t("screen_messages.register.license_error"));
      return;
    }
    if (certificate.length == 0) {
      alert(I18n.t("screen_messages.register.certificate_error"));
      return;
    }
    setLoading(true);
    async function signup() {
      let user = new UserObject();
      user.buildObject({
        name: name,
        phone_number: phoneNumber,
        email:
          email?.length !== 0
            ? email
            : `${phoneNumber}@${I18n.t(
                "screen_messages.merchant_name"
              )}.com`.replace("_", ""),
      });
      user.password = generateRandomText();
      user.company_name = namePharma;
      let addressObj = {
        line1: address,
        city: city,
        location: `${locationInfoRef.current.latitude},${locationInfoRef.current.longitude}`,
      };
      if (refer.length !== 0) {
        user.referral_code = refer;
      }
      console.log("user params===", user);
      const [error, response] = await AsyncSessionManager.signup(user);
      if (error) {
        console.log(error);
        setLoading(false);
        alert(error?.message);
      } else {
        await uploadDocuments(response.data);
        await updateAddress(response.data, addressObj);
        setLoading(false);
        setName("");
        setPhoneNumber("");
        setNamePharma("");
        setTPIN("");
        setAddress("");
        setCity("");
        setRefer("");
        setSelectedOption("");
        setZamraNumber("");
        refreshUser();
        // ConfirmAlert();
      }
    }
    signup();
  };

  const askForLocation = () => {
    return new Promise((resolve, reject) => {
      const onSuccess = ({ coords }) => {
        locationInfoRef.current = {
          latitude: coords.latitude,
          longitude: coords.longitude,
        };
        setLoading(false);
        resolve({ coords });
      };
      const onError = (error) => {
        Linking.openSettings();
        setLoading(false);
        reject(error);
        console.log(`RecordDelivery -> askForLocation -> error `, error);
      };
      setLoading(true);
      Utils.getLocationInfo({ onSuccess, onError });
    });
  };

  const setProofOfLicenseDocuments = (value) => {
    dispatch({ type: "SET_LICENSE", payload: value });
  };

  const setCertificates = (value) => {
    dispatch({ type: "SET_CERTIFICATE", payload: value });
  };

  function getAllLicens(value) {
    if (!_.isEmpty(value)) {
      askForLocation()
        .then(({ coords }) => {
          var array = license;
          let imageValues = _.first(value);

          imageValues.forEach((ele) => {
            var newarr = ele.path.split("/");

            let name = newarr.pop();
            let mimeType = ele.mime;

            var docHash = {
              document_url: ele.path,
              document_name: ele?.mime,
              document_title: name,
              document_type: mimeType,
              flag: true,
            };
            if (array.length == 1) {
              array[0] = docHash;
            } else {
              array.push(docHash);
            }
            setProofOfLicenseDocuments(array);
          });
        })
        .catch((error) =>
          console.log("RecordDelivery -> getAllImages -> error", error)
        );
    }
  }

  function getAllCertificate(value) {
    if (!_.isEmpty(value)) {
      askForLocation()
        .then(({ coords }) => {
          var array = certificate;
          let imageValues = _.first(value);

          imageValues.forEach((ele) => {
            var newarr = ele.path.split("/");

            let name = newarr.pop();

            let mimeType = ele.mime;

            var docHash = {
              document_url: ele.path,
              document_name: ele?.mime,
              document_title: name,
              document_type: mimeType,
              flag: true,
            };

            if (array.length == 1) {
              array[0] = docHash;
            } else {
              array.push(docHash);
            }
            setCertificates(array);
          });
        })
        .catch((error) =>
          console.log(
            "RecordDelivery -> getSignedInvoiceImages -> error",
            error
          )
        );
    }
  }

  const renderCertificates = () => {
    return (
      <>
        <View
          style={{
            width: "100%",
            borderWidth: 1,
            borderColor: "#CCCCCC",
            borderRadius: 20,
          }}
        >
          <View style={{}}>
            <ImageUploadButton
              borderColor={theme.white}
              deleteImage={() => {}}
              imageText={
                Config?.applicationName === "Zip Health Angola"
                  ? I18n.t("screen_messages.register.your_alvara")
                  : I18n.t("screen_messages.register.your_certificate")
              }
              getAllImages={(e) => getAllCertificate(e)}
            />
          </View>
        </View>
        {certificate.length > 0 ? (
          <View>
            {_.map(certificate, (document) => {
              return renderUploadedDocuments(document, "certificate");
            })}
          </View>
        ) : null}
      </>
    );
  };

  const renderLicenses = () => {
    return (
      <>
        <View
          style={{
            width: "100%",
            borderWidth: 1,
            borderColor: "#CCCCCC",
            borderRadius: 20,
          }}
        >
          <View style={{}}>
            <ImageUploadButton
              borderColor={theme.white}
              deleteImage={() => {}}
              imageText={
                Config?.applicationName === "Zip Health Angola"
                  ? I18n.t("screen_messages.register.your_license_ARMED")
                  : I18n.t("screen_messages.register.your_license")
              }
              getAllImages={(e) => getAllLicens(e)}
            />
          </View>
        </View>
        {license.length > 0 ? (
          <View>
            {_.map(license, (document) => {
              return renderUploadedDocuments(document, "license");
            })}
          </View>
        ) : null}
      </>
    );
  };

  const renderUploadedDocuments = (document, type) => {
    return (
      <View style={{ paddingVertical: 10 }}>
        <ImageUploadDelivery
          imageText={document.document_title}
          onPressDelete={() => onRemoveUploadedDocument(document, type)}
          showDelete={document.flag}
        />
      </View>
    );
  };

  const onRemoveUploadedDocument = (document, type) => {
    if (type == "license") {
      let result = _.filter(
        license,
        (ele) => ele.document_url != document.document_url
      );
      setProofOfLicenseDocuments(result);
    } else {
      let result = _.filter(
        certificate,
        (ele) => ele.document_url != document.document_url
      );
      setCertificates(result);
    }
  };

  console.log("selectedOption===========", selectedOption);

  const setPharmacyValue = (id) => {
    let a = id();
    setSelectedOption(a);
    let selectedlabel = pharmacy.find((obj) => obj.value === a);
    setSelectedLabel(selectedlabel.label);
  };

  return (
    <LoadingContainer style={{ flex: 1 }} loading={loading}>
      <TopHeaderWithTitle
        title={title}
        shouldGoBack={shouldGoBack}
        onPressLeftButton={goBack}
      />
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          contentContainerStyle={{
            paddingTop: Platform.OS == "android" ? 0 : 0,
          }}
        >
          <View
            style={{
              backgroundColor: theme.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 30,
              paddingBottom: 10,
            }}
          >
            <Image
              source={Images.logo_transparent}
              style={{
                width: 120,
                height: 80,
              }}
            />
          </View>

          <VerticalSpacing size={10} />

          {Config?.applicationName === "Zip Health Angola" ? (
            <View
              style={{
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
                paddingRight: 30,
              }}
            >
              <PrestoText
                color={theme.primary}
                size={16}
                fontStyle={"600.semibold"}
              >
                {I18n.t("screen_messages.language.portugese")}
              </PrestoText>
              <View style={{ marginHorizontal: 6 }}>
                <Switch
                  trackColor={{
                    false: "#CCCCCC",
                    true: "#CCCCCC",
                  }}
                  thumbColor={theme.primary}
                  ios_backgroundColor={theme.primary}
                  onValueChange={toggleNetwork}
                  value={language === "en-US"}
                />
              </View>
              <PrestoText
                color={theme.primary}
                size={16}
                fontStyle={"600.semibold"}
              >
                {I18n.t("screen_messages.language.english")}
              </PrestoText>
            </View>
          ) : null}

          <View style={{}}>
            <FullWidthHorizontalPaddedBox>
              <VerticalSpacing size={20} />
              {!documentError ? (
                <>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_mobile_number")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.register.your_mobile_number"
                    )}
                    value={phoneNumber}
                    keyboardType={"phone-pad"}
                    onChange={onPhoneNumberChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_email")}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t("screen_messages.register.your_email")}
                    value={email}
                    onChange={onEmailChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_pharmacy_name")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.register.your_pharmacy_name"
                    )}
                    value={namePharma}
                    onChange={onPharmaNameChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />

                  <View style={styles?.container}>
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <PrestoText
                        size={15}
                        fontStyle="500.semibold"
                        color={theme.dark}
                        extraStyle={{ paddingLeft: 5 }}
                      >
                        {I18n.t("screen_messages.register.pharmacy_type")}
                      </PrestoText>
                      <PrestoText
                        size={16}
                        fontStyle="500.semibold"
                        color={theme.error}
                        extraStyle={{ paddingLeft: 5 }}
                      >
                        {"*"}
                      </PrestoText>
                    </View>
                    <VerticalSpacing size={15} />

                    <View style={{ zIndex: 1 }}>
                      <DropDownPicker
                        dropDownDirection="BOTTOM"
                        placeholder={I18n.t(
                          "screen_messages.register.pharmacy_type"
                        )}
                        open={open}
                        value={selectedOption}
                        items={pharmacy}
                        setOpen={setOpen}
                        setValue={(e) => setPharmacyValue(e)}
                        setItems={setPharmancy}
                        style={{
                          borderWidth: 1,
                          borderColor: "#CCCC",
                          borderRadius: 20,
                          paddingLeft: 20,
                          overflow: "hidden",
                          backgroundColor: theme.white,
                        }}
                        textStyle={{
                          fontSize: 16,
                          color: theme.placeHolderColor,
                        }}
                        listMode="SCROLLVIEW"
                        dropDownContainerStyle={{
                          position: "relative",
                          top: 0,
                          borderColor: "#CCCC",
                        }}
                        labelStyle={{
                          fontSize: 16,
                          color: theme.textInputColor || "#000",
                        }}
                      />
                    </View>

                    {/* <View style={styles.pickerContainer}>
                    {Config?.applicationName === "Zip Health Angola" ? (
                      <Picker
                        selectedValue={selectedOption}
                        onValueChange={(itemValue) =>
                          setSelectedOption(itemValue)
                        }
                        style={styles?.picker}
                      >
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.pharmacy_type"
                          )}
                          value=""
                          style={{ color: theme.placeHolderColor }}
                        />
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.pharmacy_text"
                          )}
                          value="Pharmacy"
                          style={{ color: theme.dark }}
                        />
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.medical_center_text"
                          )}
                          value="Medical Center"
                          style={{ color: theme.dark }}
                        />
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.general_store_text"
                          )}
                          value="General Stores"
                          style={{ color: theme.dark }}
                        />
                      </Picker>
                    ) : (
                      <Picker
                        selectedValue={selectedOption}
                        onValueChange={(itemValue) =>
                          setSelectedOption(itemValue)
                        }
                        style={styles?.picker}
                      >
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.pharmacy_type"
                          )}
                          value=""
                          style={{ color: theme.placeHolderColor }}
                        />
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.pharmacy_text"
                          )}
                          value="Pharmacy"
                          style={{ color: theme.dark }}
                        />
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.healthshop_text"
                          )}
                          value="Healthshop"
                          style={{ color: theme.dark }}
                        />
                        <Picker.Item
                          label={I18n.t(
                            "screen_messages.register.general_store_text"
                          )}
                          value="General Stores"
                          style={{ color: theme.dark }}
                        />
                      </Picker>
                    )}
                  </View> */}
                  </View>

                  <VerticalSpacing size={25} />

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_name")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t("screen_messages.register.your_name")}
                    value={name}
                    onChange={onNameChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {Config?.applicationName === "Zip Health Angola"
                        ? I18n.t("screen_messages.register.your_nif")
                        : I18n.t("screen_messages.register.your_tpin")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={
                      Config?.applicationName === "Zip Health Angola"
                        ? I18n.t("screen_messages.register.your_nif")
                        : I18n.t("screen_messages.register.your_tpin")
                    }
                    value={TPIN}
                    onChange={onTPINChange}
                    backgroundColor={theme.white}
                    keyboardType={"phone-pad"}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_address")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.register.your_address"
                    )}
                    value={address}
                    onChange={onAddressChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_city")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t("screen_messages.register.your_city")}
                    value={city}
                    onChange={onCityChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_reffered_by")}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.register.your_reffered_by"
                    )}
                    value={refer}
                    onChange={onReferChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />
                </>
              ) : null}

              {Config?.applicationName === "Zip Health Zambia" ? (
                <>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <PrestoText
                      size={15}
                      fontStyle="500.semibold"
                      color={theme.dark}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {I18n.t("screen_messages.register.your_zamra_number")}
                    </PrestoText>
                    <PrestoText
                      size={16}
                      fontStyle="500.semibold"
                      color={theme.error}
                      extraStyle={{ paddingLeft: 5 }}
                    >
                      {"*"}
                    </PrestoText>
                  </View>
                  <VerticalSpacing size={15} />
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.register.your_zamra_number"
                    )}
                    value={zamraNumber}
                    // keyboardType={"phone-pad"}
                    onChange={onZamraNumberChange}
                    backgroundColor={theme.white}
                  ></PrestoTextInput>

                  <VerticalSpacing size={30} />
                </>
              ) : null}

              {renderLicenses()}

              <VerticalSpacing size={30} />

              {renderCertificates()}

              <VerticalSpacing size={30} />

              <PrestoSolidButton
                size="normal"
                title={
                  !documentError
                    ? I18n.t("screen_messages.register.register_text")
                    : I18n.t("screen_messages.register.upload_text")
                }
                onPress={!documentError ? onSignup : reUploadDocument}
                borderRadius={20}
                bgColor={theme.primary}
                titleExtraStyle={{
                  fontSize: 18,
                }}
                height={50}
              />

              <VerticalSpacing size={50} />
            </FullWidthHorizontalPaddedBox>
          </View>
        </KeyboardAwareScrollView>
      </TouchableWithoutFeedback>
    </LoadingContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  pickerContainer: {
    borderWidth: 1,
    borderColor: "#CCCC",
    borderRadius: 20,
    paddingLeft: 10,
    overflow: "hidden",
  },
  picker: {
    height: 50,
  },
});
