import ProfileResource from "./ProfileResource";
import ErrorConstants from "../../common/ErrorConstants";
import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import UserObject from "../../data_models/UserObject";
import $ from "../../global/util";

const ProfileManager = function (
  ProfileResource,
  ErrorConstants,
  Utils,
  PrestoSdk,
  UserObject
) {
  function getProfile(user, successCallback, errorCallback) {
    if (user) {
      var validationResponse = user.validateObject();
      if (validationResponse.success) {
        console.log("getProfile making network call");
        ProfileResource.getProfile(user).then(
          function (user) {
            user.message = Utils.getSuccessMessage("profile_updated");
            console.log("updateProfile success");
            var userObj = new UserObject();
            userObj.buildObject(user.data);
            user.data = userObj;
            successCallback(user);
          },
          function (error) {
            error = Utils.updateErrorObject(error);
            errorCallback(error);
          }
        );
      } else {
        validationResponse = Utils.updateErrorObject(validationResponse);
        errorCallback(validationResponse);
      }
    } else {
      ProfileResource.getProfile().then(
        function (user) {
          user.message = Utils.getSuccessMessage("profile_updated");
          console.log("updateProfile success");
          var userObj = new UserObject();
          userObj.buildObject(user.data);
          user.data = userObj;
          successCallback(user);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getMyProfile(user, successCallback, errorCallback) {
    ProfileResource.getMyProfile().then(
      function (user) {
        user.message = Utils.getSuccessMessage("profile_updated");
        console.log("updateProfile success");
        successCallback(user);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateProfile(user, successCallback, errorCallback) {
    var validationResponse = user.validateObject();
    if (validationResponse.success) {
      console.log("updateProfile making network call");
      ProfileResource.updateProfile(user).then(
        function (updateProfileResponse) {
          updateProfileResponse.message = Utils.getSuccessMessage(
            "profile_updated"
          );
          console.log("updateProfile success");
          var userObj = new UserObject();
          userObj.buildObject(updateProfileResponse.data);
          updateProfileResponse.data = userObj;
          successCallback(updateProfileResponse);
        },
        function (error) {
          error = error.data;
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function updateProfileDocument(user, successCallback, errorCallback) {
    ProfileResource.updateProfileDocument(user).then(
      function (updateProfileResponse) {
        updateProfileResponse.message = Utils.getSuccessMessage(
          "profile_updated"
        );
        console.log("updateProfile success");
        var userObj = new UserObject();
        userObj.buildObject(updateProfileResponse.data);
        updateProfileResponse.data = userObj;
        successCallback(updateProfileResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteAddress(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.userId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.USER_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else if (Utils.isEmpty(params.addressId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ADDRESS_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      ProfileResource.deleteAddress(params).then(
        function (deleteAddressResponse) {
          successCallback(deleteAddressResponse);
        },
        function (error) {
          error = error.data;
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function updateAddress(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.userId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.USER_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else if (Utils.isEmpty(params.addressId)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ADDRESS_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      ProfileResource.updateAddress(params).then(
        function (updateAddressResponse) {
          successCallback(updateAddressResponse);
        },
        function (error) {
          error = error.data;
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function addAdditionalData(params, successCallback, errorCallback) {
    ProfileResource.addAdditionalData(params).then(
      function (addAdditionalDataResponse) {
        successCallback(addAdditionalDataResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAdditionalData(params, successCallback, errorCallback) {
    ProfileResource.getAdditionalData(params).then(
      function (getAdditionalDataResponse) {
        successCallback(getAdditionalDataResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getUserBonusCoupon(successCallback, errorCallback) {
    ProfileResource.getUserBonusCoupon().then(
      function (getUserBonusCouponResponse) {
        successCallback(getUserBonusCouponResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getActiveSubscription(params, successCallback, errorCallback) {
    ProfileResource.getActiveSubscription(params).then(
      function (getActiveSubscriptionResponse) {
        successCallback(getActiveSubscriptionResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAvailableAccounts(successCallback, errorCallback) {
    ProfileResource.getAvailableAccounts().then(
      function (getAvailableAccountsResponse) {
        successCallback(getAvailableAccountsResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getCustomerInfo(params, successCallback, errorCallback) {
    ProfileResource.getCustomerInfo(params).then(
      function (getUserInfoResponse) {
        successCallback(getUserInfoResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function addAddress(params, successCallback, errorCallback) {
    ProfileResource.addAddress(params).then(
      function (addAddressResponse) {
        successCallback(addAddressResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function virtualAccountDetail(params, successCallback, errorCallback) {
    ProfileResource.virtualAccountDetail(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function deleteUserAccount(params, successCallback, errorCallback) {
    ProfileResource.deleteUserAccount(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  return {
    updateAddress: updateAddress,
    updateProfile: updateProfile,
    getProfile: getProfile,
    deleteAddress: deleteAddress,
    addAdditionalData: addAdditionalData,
    getAdditionalData: getAdditionalData,
    getUserBonusCoupon: getUserBonusCoupon,
    getActiveSubscription: getActiveSubscription,
    getAvailableAccounts: getAvailableAccounts,
    getCustomerInfo: getCustomerInfo,
    addAddress: addAddress,
    virtualAccountDetail: virtualAccountDetail,
    deleteUserAccount: deleteUserAccount,
    updateProfileDocument: updateProfileDocument,
    getMyProfile: getMyProfile,
  };
};

export default ProfileManager(
  ProfileResource,
  ErrorConstants,
  Utils,
  PrestoSdk,
  UserObject
);
