import DocumentResource from "./DocumentResource";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const DocumentManager = function (
  DocumentResource,
  Utils,
  ErrorConstants,
  PrestoSdk
) {
  function getDocuments(successCallback, errorCallback) {
    DocumentResource.getDocuments().then(
      function (documentsResponse) {
        successCallback(documentsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getDocumentDetails(params, successCallback, errorCallback) {
    DocumentResource.getDocumentDetails(params).then(
      function (documentDetailsResponse) {
        successCallback(documentDetailsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createDocument(params, successCallback, errorCallback) {
    DocumentResource.createDocument(params).then(
      function (createDocumentResponse) {
        successCallback(createDocumentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateDocument(params, successCallback, errorCallback) {
    DocumentResource.updateDocument(params).then(
      function (updateDocumentResponse) {
        successCallback(updateDocumentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteDocument(params, successCallback, errorCallback) {
    DocumentResource.deleteDocument(params).then(
      function (deleteDocumentResponse) {
        successCallback(deleteDocumentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function uploadDocument(params, successCallback, errorCallback) {
    DocumentResource.uploadDocument(params).then(
      function (uploadDocumentResponse) {
        successCallback(uploadDocumentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function uploadFile(params, successCallback, errorCallback) {
    DocumentResource.uploadFile(params).then(
      function (uploadDocResponse) {
        successCallback(uploadDocResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createEmployeeDocument(params, successCallback, errorCallback) {
    DocumentResource.createEmployeeDocument(params).then(
      function (createEmployeeDocumentResponse) {
        successCallback(createEmployeeDocumentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateDocumentsHash(params, successCallback, errorCallback) {
    DocumentResource.updateDocumentsHash(params).then(
      function (updateDocumentsHashResponse) {
        successCallback(updateDocumentsHashResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createAdminDocument(params, successCallback, errorCallback) {
    DocumentResource.createAdminDocument(params).then(
      function (createAdminDocumentResponse) {
        successCallback(createAdminDocumentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    createAdminDocument: createAdminDocument,
    getDocuments: getDocuments,
    getDocumentDetails: getDocumentDetails,
    createDocument: createDocument,
    updateDocument: updateDocument,
    deleteDocument: deleteDocument,
    uploadDocument: uploadDocument,
    uploadFile: uploadFile,
    createEmployeeDocument: createEmployeeDocument,
    updateDocumentsHash: updateDocumentsHash,
  };
};

export default DocumentManager(
  DocumentResource,
  Utils,
  ErrorConstants,
  PrestoSdk
);
