import React from "react";
import { ScrollView, View, Text } from "react-native";
import Tabs from "@presto-screens/Tabs/Tabs";
import HomeScreen from "@presto-screens/Home/Home";
import LoginScreen from "@presto-screens/Login/Login";
import BrowseScreen from "@presto-screens/Browse/Browse";
import SubCategoriesBrowseScreen from "@presto-screens/SubCategoriesBrowse/SubCategoriesBrowse";
import SearchScreen from "@presto-screens/SearchResults/SearchResults";
import CartScreen from "@presto-screens/Cart/Cart";
import FavouritesScreen from "@presto-screens/Favourites/Favourites";
import ProfileScreen from "@presto-screens/Profile/Profile";
import LoyaltyPointsScreen from "@presto-screens/LoyaltyPointsScreen/LoyaltyPointsScreen";
import OrdersList from "@presto-screens/OrdersList/OrdersList";
import TrackOrder from "@presto-screens/TrackOrder/TrackOrder";
import OrderDetails from "@presto-screens/OrderDetails/OrderDetails";
import Addresses from "@presto-screens/Addresses/Addresses";
import OrderInvoice from "@presto-screens/OrderInvoice/OrderInvoice";
import CartPayment from "@presto-screens/CartPayment/CartPayment";
import CartCheckoutScreen from "@presto-screens/CartCheckout/CartCheckout";
import OrderSuccessScreen from "@presto-screens/OrderSuccess/OrderSuccess";
import DetailedItemScreen from "@presto-screens/DetailedItemScreen/DetailedItemScreen";
import KeywordBrowseScreen from "@presto-screens/KeywordBrowse/KeywordBrowse";
import SortOptionsScreen from "@presto-screens/SortOptions/SortOptions";
import FilterOptionsScreen from "@presto-screens/FilterOptions/FilterOptions";
import OffersScreen from "@presto-screens/OffersScreen/OffersScreen";
import ReportAProblem from "@presto-screens/Feedback/ReportAProblem";
// import RegisterScreen from "@presto-screens/Register/RegisterScreen";
import FreeItems from "@presto-screens/FreeItems/FreeItems";
import ViewAllDeals from "@presto-screens/Deals/ViewAllDeals";
import ChooseLanguage from "@presto-screens/ChooseLanguage/ChooseLanguage";
import WebHome from "@presto-screens/Home/WebHome";
import SearchScreenHome from "@presto-screens/Home/SearchScreenHome";
import RegisterWebView from "@presto-screens/Register/RegisterWebView";
import RegisterScreen from "@presto-screens/Register/Register.ziphealth";
import Refer from "@presto-screens/Refer/Refer";
import OnlinePayment from "@presto-screens/Payments/OnlinePayment";
import StoreIdModal from "@presto-screens/GetStoreId/GetStoreId";
import ReviewDetails from "@presto-screens/ReviewDetails/ReviewDetails";
import RedeemPointsScreen from "@presto-screens/LoyaltyPointsScreen/RedeemPointsScreen";
import OfferGalleryScreen from "@presto-screens/OffersScreen/HomeOffersScreen";
import LoginWithOtpScreen from "@presto-screens/LoginWithOtp/LoginWithOtp";
import ListItemsAvailableForDiscount from "@presto-screens/ListItemsAvailableForDiscount/ListItemsAvailableForDiscount";
import RedirectionLoading from "@presto-screens/Loading/RedirectionLoading";
import Transactions from "@presto-screens/Transactions/Transactions";
import PaymentOptions from "@presto-screens/Payments/PaymentOptions";

function EmptyScreen() {
  return (
    <ScrollView style={{}}>
      <View
        style={{
          width: "100%",
          height: 1000,
          justifyContent: "center",
          alignItems: "center",
        }}
      ></View>
    </ScrollView>
  );
}

export default {
  mobile: {
    logged_out: "LOGGED_OUT",
    logged_in: "TABS",
    screens: {
      TABS: {
        path: "/",
        component: Tabs,
        type: "TABS",
        tabScreens: [
          {
            screen: "HOME_TAB_SCREEN",
            name: "HOME",
            path: "homet",
          },
          {
            screen: "BROWSE_SCREEN",
            name: "CATEGORIES",
            path: "categories",
          },
          {
            screen: "CART_SCREEN",
            name: "CART",
            path: "cart",
          },
          {
            screen: "FAVOURITES_SCREEN",
            name: "MY_PRODUCTS",
            path: "favorites",
          },
          {
            screen: "PROFILE_SCREEN",
            name: "ACCOUNT",
            path: "profile",
          },
        ],
      },
      LOGGED_OUT: {
        path: "login",
        component: LoginScreen,
        header: false,
      },
      HOME_TAB_SCREEN: {
        path: "hometm",
        component: HomeScreen,
        header: false,
      },
      BROWSE_SCREEN: {
        path: "browse",
        component: BrowseScreen,
        header: false,
      },
      SUB_CATEGORIES_BROWSE_SCREEN: {
        path: "browse/:category_id",
        component: SubCategoriesBrowseScreen,
        header: false,
      },
      CART_SCREEN: {
        path: "cart_screen",
        component: CartScreen,
        header: false,
      },
      FAVOURITES_SCREEN: {
        path: "favourites",
        component: FavouritesScreen,
        header: false,
      },
      PROFILE_SCREEN: {
        path: "profile_screen",
        component: ProfileScreen,
        header: false,
      },
      ADDRESSES_SCREEN: {
        path: "addresses",
        component: Addresses,
        header: false,
      },
      ORDERS_LIST_SCREEN: {
        path: "orders",
        component: OrdersList,
        header: false,
      },
      ORDER_DETAILS_SCREEN: {
        path: "orderdetails",
        component: OrderDetails,
        header: false,
      },
      TRACK_ORDER_SCREEN: {
        path: "trackorder",
        component: TrackOrder,
        header: false,
      },
      ORDER_INVOICE_SCREEN: {
        path: "invoice",
        component: OrderInvoice,
        header: false,
      },
      LOYALTY_POINT_TRANSACTIONS_SCREEN: {
        path: "points",
        component: LoyaltyPointsScreen,
        header: false,
      },
      EMPTY_TAB_SCREEN: {
        component: EmptyScreen,
        header: false,
      },
      SEARCH_RESULTS_SCREEN: {
        path: "search",
        component: SearchScreen,
        header: false,
      },
      CART_CHECKOUT_SCREEN: {
        path: "checkout",
        component: CartCheckoutScreen,
        header: false,
      },
      CART_PAYMENT_SCREEN: {
        path: "cartpayment",
        component: CartPayment,
        header: false,
      },
      ORDER_SUCCESSFUL_SCREEN: {
        path: "ordersuccess",
        component: OrderSuccessScreen,
        header: false,
      },
      DETAILED_ITEM: {
        path: "item",
        component: DetailedItemScreen,
        header: false,
        fadeIn: true,
      },
      KEYWORD_BROWSE_SCREEN: {
        path: "kbrowse",
        component: KeywordBrowseScreen,
        header: false,
      },
      SORT_OPTIONS_SCREEN: {
        path: "sort",
        component: SortOptionsScreen,
        header: false,
      },
      FILTER_OPTIONS_SCREEN: {
        path: "filter",
        component: FilterOptionsScreen,
        header: false,
      },
      OFFERS_SCREEN: {
        path: "offers",
        component: OffersScreen,
        header: false,
      },
      REPORT_A_PROBLEM: {
        path: "report",
        component: ReportAProblem,
        header: false,
      },
      SIGNUP_SCREEN: {
        path: "signup",
        component: RegisterScreen,
        header: false,
      },
      FREE_ITEMS_SCREEN: {
        path: "free",
        component: FreeItems,
        header: false,
      },
      CHOOSE_LANGUAGE: {
        path: "chooselanguage",
        component: ChooseLanguage,
        header: false,
      },
      VIEW_ALL_DEALS: {
        path: "deals",
        component: ViewAllDeals,
        header: false,
      },
      HUB_SPOT_FORM: {
        path: "sign_up",
        component: RegisterWebView,
        header: false,
      },
      REFER_SCREEN: {
        path: "refer_friend",
        component: Refer,
        header: false,
      },
      ONLINE_PAYMENT_SCREEN: {
        path: "online_payment",
        component: OnlinePayment,
        fadeIn: true,
      },
      GET_STORE_ID: {
        path: "get_store_id",
        component: StoreIdModal,
        fadeIn: true,
      },
      REVIEW_SCREEN: {
        path: "reviewdetails",
        component: ReviewDetails,
        header: false,
      },
      REDEEM_POINTS_SCREEN: {
        path: "redeem_points",
        component: RedeemPointsScreen,
        header: false,
      },
      HOME_OFFER_SCREEN: {
        path: "home_offers",
        component: OfferGalleryScreen,
        header: false,
        fadeIn: true,
      },
      LOGIN_WITH_OTP_SCREEN: {
        path: "login_otp",
        component: LoginWithOtpScreen,
        header: false,
        fadeIn: true,
      },
      LIST_ITEMS_AVAILABLE_FOR_DISCOUNT: {
        path: "list_items_available_for_discount",
        component: ListItemsAvailableForDiscount,
        header: false,
      },
      REDIRECTION_LOADING: {
        path: "redirection_loading",
        component: RedirectionLoading,
        header: false,
      },
      TRANSACTIONS: {
        path: "transactions",
        component: Transactions,
        header: false,
      },
      PAYMENT_OPTIONS: {
        path: "paymentoptions",
        component: PaymentOptions,
        header: false,
        fadeIn: true,
      },
    },
  },
  desktop: {
    logged_out: "LOGIN",
    logged_in: "HOME",
    main: "HOME",
    screens: {
      LOGIN: {
        path: "login",
        component: LoginScreen,
        header: false,
      },
      HOME: {
        path: "home",
        component: WebHome,
        header: false,
      },
      SEARCH_RESULTS_SCREEN: {
        path: "search",
        component: WebHome,
        header: false,
      },
      SEARCH_RESULTS_HOME: {
        path: "search_home",
        component: SearchScreenHome,
        header: false,
      },
      CART: {
        path: "cart",
        component: CartScreen,
        header: false,
      },

      FAVOURITES: {
        path: "favourites",
        component: FavouritesScreen,
        header: false,
      },
      ACCOUNT: {
        path: "account",
        component: ProfileScreen,
        header: false,
      },
      ADDRESSES_SCREEN: {
        path: "addresses",
        component: Addresses,
        header: false,
      },
      ORDERS_LIST_SCREEN: {
        path: "orders",
        component: OrdersList,
        header: false,
      },
      ORDER_DETAILS_SCREEN: {
        path: "orderdetails",
        component: OrderDetails,
        header: false,
      },
      TRACK_ORDER_SCREEN: {
        path: "trackorder",
        component: TrackOrder,
        header: false,
      },
      ORDER_INVOICE_SCREEN: {
        path: "invoice",
        component: OrderInvoice,
        header: false,
      },
      LOYALTY_POINT_TRANSACTIONS_SCREEN: {
        path: "points",
        component: LoyaltyPointsScreen,
        header: false,
      },
      EMPTY_TAB_SCREEN: {
        component: EmptyScreen,
        header: false,
      },
      CART_CHECKOUT_SCREEN: {
        path: "checkout",
        component: CartCheckoutScreen,
        header: false,
        fadeIn: true,
      },
      CART_PAYMENT_SCREEN: {
        path: "cartpayment",
        component: CartPayment,
        header: false,
        fadeIn: true,
      },
      ORDER_SUCCESSFUL_SCREEN: {
        path: "ordersuccess",
        component: OrderSuccessScreen,
        header: false,
      },
      DETAILED_ITEM: {
        path: "item",
        component: DetailedItemScreen,
        header: false,
      },
      KEYWORD_BROWSE_SCREEN: {
        path: "kbrowse",
        component: KeywordBrowseScreen,
        header: false,
      },
      SORT_OPTIONS_SCREEN: {
        path: "sort",
        component: SortOptionsScreen,
        header: false,
      },
      FILTER_OPTIONS_SCREEN: {
        path: "filter",
        component: FilterOptionsScreen,
        header: false,
      },
      OFFERS_SCREEN: {
        path: "offers",
        component: OffersScreen,
        header: false,
      },
      REPORT_A_PROBLEM: {
        path: "report",
        component: ReportAProblem,
        header: false,
      },
      SIGNUP_SCREEN: {
        path: "signup",
        component: RegisterScreen,
        header: false,
      },
      FREE_ITEMS_SCREEN: {
        path: "free",
        component: FreeItems,
        header: false,
      },
      CHOOSE_LANGUAGE: {
        path: "chooselanguage",
        component: ChooseLanguage,
        header: false,
      },
      VIEW_ALL_DEALS: {
        path: "deals",
        component: ViewAllDeals,
        header: false,
      },
      HUB_SPOT_FORM: {
        path: "sign_up",
        component: RegisterWebView,
        header: false,
      },
      REFER_SCREEN: {
        path: "refer_friend",
        component: Refer,
        header: false,
      },
      GET_STORE_ID: {
        path: "get_store_id",
        component: StoreIdModal,
        fadeIn: true,
      },

      REVIEW_SCREEN: {
        path: "reviewdetails",
        component: ReviewDetails,
        header: false,
      },
      REDEEM_POINTS_SCREEN: {
        path: "redeem_points",
        component: RedeemPointsScreen,
        header: false,
      },
      HOME_OFFER_SCREEN: {
        path: "home_offers",
        component: OfferGalleryScreen,
        header: false,
        fadeIn: true,
      },
      TRANSACTIONS: {
        path: "transactions",
        component: Transactions,
        header: false,
      },
    },
  },
};
