import React, { useContext } from "react";
import Svg, { Defs, Path, G, Mask, Use } from "react-native-svg";
import ThemeContext from "@presto-contexts/ThemeContext";

function ImageUploadIcon(props) {
  const { theme } = useContext(ThemeContext);

  return (
    <Svg
      width="30px"
      height="30px"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Defs>
        <Path id="a" d="M0 0H20V26H0z" />
      </Defs>
      <G
        transform="translate(5 5)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <G transform="matrix(1 0 0 -1 5 26)">
          <Mask id="b" fill="#fff">
            <Use xlinkHref="#a" />
          </Mask>
          <G
            mask="url(#b)"
            fill={props?.color || theme.secondary}
            fillRule="nonzero"
          >
            <Path
              transform="translate(0 -.015) rotate(90 10 13.008)"
              d="M13.0075466 3.00754656L11.1887966 4.82629656 18.0712966 11.7087966 -3.00754656 12.0075466 -3.00754656 14.3062966 18.0725466 14.3062966 11.1887966 21.1887966 13.0075466 23.0075466 23.0075466 13.0075466z"
            />
          </G>
        </G>
        <Path
          stroke={props?.color || theme.secondary}
          strokeWidth={3}
          d="M0 20.5L0 30 29 30 29 20.5"
        />
      </G>
    </Svg>
  );
}

export default ImageUploadIcon;
