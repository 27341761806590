import Utils from "../../common/Utils";
import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const DocumentResource = function (Utils, $http, PrestoSdk) {
  function getDocuments() {
    var url = PrestoSdk.getHostName() + "/documents.json";
    return $http.get(url);
  }

  function getDocumentDetails(params) {
    var url =
      PrestoSdk.getHostName() + "/documents/" + params.document_id + ".json";
    return $http.get(url);
  }

  function createDocument(params) {
    var url = PrestoSdk.getHostName() + "/user/v1/documents.json";
    const header = {
      "Content-Type": "multipart/form-data",
      Accept: "text/plain",
    };
    return $http.post(url, params,header);
  }

  function updateDocument(params) {
    var url =
      PrestoSdk.getHostName() + "/documents/" + params.document_id + ".json";
    return $http.put(url, params);
  }

  function deleteDocument(params) {
    var url =
      PrestoSdk.getHostName() + "/documents/" + params.document_id + ".json";
    return $http.delete(url);
  }

  function uploadDocument(params) {
    var url = PrestoSdk.getHostName() + "/admin/v0/documents.json";
    console.log("url -- ", url);
    console.log("params -- ", params);

    const header = { "Content-Type": "multipart/form-data" };
    return $http.post(url, params, header, { body: params });
  }

  function uploadFile(params) {
    var url = PrestoSdk.getHostName() + "/uploads";
    const header = {
      "Content-Type": "multipart/form-data",
      Accept: "text/plain",
    };
    return $http.post(url, params, header);
  }

  function createEmployeeDocument(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/documents.json";
    const header = {
      "Content-Type": "multipart/form-data",
      Accept: "text/plain",
    };
    return $http.post(url, params, header);
  }

  function updateDocumentsHash(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/orders/" +
      params.id +
      "/update_documents_hash.json";
    return $http.put(url, params);
  }

  function createAdminDocument(params) {
    var url = PrestoSdk.getHostName() + "/admin/v1/documents.json";
    const header = {
      "Content-Type": "multipart/form-data",
      Accept: "text/plain",
    };
    return $http.post(url, params, header);
  }

  return {
    createAdminDocument: createAdminDocument,
    getDocuments: getDocuments,
    getDocumentDetails: getDocumentDetails,
    createDocument: createDocument,
    updateDocument: updateDocument,
    deleteDocument: deleteDocument,
    uploadDocument: uploadDocument,
    uploadFile: uploadFile,
    createEmployeeDocument: createEmployeeDocument,
    updateDocumentsHash: updateDocumentsHash,
  };
};

export default DocumentResource(Utils, $http, PrestoSdk);
