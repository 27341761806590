import React, { useState, useContext, useEffect } from "react";
import { Card } from "@presto-components/Cards/Cards";
import { View, Image, TouchableWithoutFeedback, Pressable } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";
import ThemeContext from "@presto-contexts/ThemeContext";
import DeleteIcon from "./DeleteIcon";
import RadioButtonCheck from "./RadioButtonCheck";

export default function ImageUploadDelivery({
  imageText,
  onPressDelete,
  showDelete,
}) {
  const { svgs } = useAssets();
  const [image, setImage] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <RadioButtonCheck />
        <View style={{ paddingLeft: 10, width: "88%" }}>
          <PrestoText
            fontStyle="400.regular"
            size={14}
            color={theme.h4 || "#707070"}
            numberOfLines={2}
          >
            {imageText || "img001.jpg"}
          </PrestoText>
        </View>
      </View>
      {showDelete ? (
        <TouchableWithoutFeedback onPress={() => onPressDelete()}>
          <View
            style={{
              alignItems: "flex-end",
            }}
          >
            <DeleteIcon />
          </View>
        </TouchableWithoutFeedback>
      ) : null}
    </View>
  );
}
