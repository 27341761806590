import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import { join } from "lodash";
import config from "@presto-common/config";
import _ from "lodash";

const SearchResource = function (Utils, PrestoSdk, $http) {
  function getSearchHostName() {
    var stagingUrl =
      "https://search-prestostaging-xrrrt5jltpheeyvmkxzojrylvi.ap-south-1.es.amazonaws.com";
    var productionUrl =
      "https://search-search1-prod-presto-apps-o4w62cxnvxf3ko57g34sausxom.ap-south-1.es.amazonaws.com";
    // "https://search-search1-prod-presto-apps-6m25n374n67bcoti234lvi4n3u.me-central-1.es.amazonaws.com";
    var ooredooProductionUrl =
      "https://search-ooredoomaldives-presto-prod-fqgcrza5zwpz3g7flhfx7cj5wa.ap-south-1.es.amazonaws.com";
    var ubuProductionUrl =
      "https://search-presto-search-prod-ubu-db3qs47re6ufozgj6weoe5ovpu.eu-west-1.es.amazonaws.com";
    var zipZambiaProdUrl =
      "https://search-search1-prod-presto-apps-6m25n374n67bcoti234lvi4n3u.me-central-1.es.amazonaws.com";
    if (
      PrestoSdk.getHostName().indexOf("prestoweb") >= 0 ||
      PrestoSdk.getHostName().indexOf("prestostaging") >= 0 ||
      PrestoSdk.getHostName().indexOf("prestotelco") >= 0 ||
      PrestoSdk.getHostName().indexOf("api.staging") >= 0
    ) {
      return stagingUrl;
    } else if (PrestoSdk.getHostName().indexOf("presto.ooredoo") >= 0) {
      return ooredooProductionUrl;
    } else if (PrestoSdk.getHostName().indexOf("presto.ubu") >= 0) {
      return ubuProductionUrl;
    } else if (PrestoSdk.getHostName().indexOf("yogesh") >= 0) {
      return stagingUrl;
    } else if (PrestoSdk.getHostName().indexOf("zipafrica") >= 0) {
      return zipZambiaProdUrl;
    } else {
      return productionUrl;
    }
  }

  function getMerchantCommonIndex() {
    if (PrestoSdk.getHostName().indexOf("presto.ubu") >= 0) {
      return "merchants";
    } else {
      return "presto_merchants_staging_common";
    }
  }

  const searchAuth = config?.search_auth;

  const configHeader = {
    "Content-Type": "application/json",
    ...(searchAuth && { Authorization: searchAuth }),
    // ...(!searchAuth && { withCredentials: false }),
  };

  function fuzzySearchCategoryItem(params) {
    const appConfig = PrestoSdk.getAppConfig();
    var sortParam = "";
    if (params.price_low) {
      sortParam = "sort=price.base_price:asc";
    } else if (params.price_high) {
      sortParam = "sort=price.base_price:desc";
    } else if (params.name_asc) {
      sortParam = "sort=name.keyword:asc";
    } else if (params.name_desc) {
      sortParam = "sort=name.keyword:desc";
    } else if (params.search_string) {
      sortParam = "";
    } else {
      sortParam = "sort=listing_order";
    }
    const merchantId = params.merchant_id || PrestoSdk.getMerchantId();

    let url =
      getSearchHostName() + "/" + merchantId + "_category_items/_search?";
    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "category_items/_search?";
    }
    let size = params.size || "25";
    if (sortParam) {
      url += sortParam + "&size=" + size;
    } else {
      url += "size=" + size;
    }
    var queryParams = "";
    if (params.page && params.page >= 1) {
      var from = (params.page - 1) * size;
      queryParams += "&from=" + from;
    }
    url += queryParams;

    let shoulds = [];

    let mustNots = [];

    let musts = [
      {
        match_phrase: {
          is_available: true,
        },
      },
    ];
    if (!params.isExcludeMerchantId) {
      musts.push({
        match_phrase: {
          merchant_id: params.merchant_id || PrestoSdk.getMerchantId(),
        },
      });
    }

    if (params.catalog_id) {
      musts.push({
        match_phrase: {
          catalog_id: params.catalog_id,
        },
      });
    }

    if (params.on_offer) {
      musts.push({
        match_phrase: {
          on_offer: true,
        },
      });
    }
    if (params.medicine==0) {
      musts.push({
        match_phrase: {
          "other_data.medicine": "false",
        },
      });
    }

    if (params.certified) {
      musts.push({
        match_phrase: {
          "other_data.certified": params.certified,
        },
      });
    }

    if (params.best_selling) {
      musts.push({
        match_phrase: {
          "other_data.best_selling": params.best_selling,
        },
      });
    }
    if (params.refit_recommended) {
      musts.push({
        match_phrase: {
          "other_data.refit_recommended": params.refit_recommended,
        },
      });
    }
    if (params.other_on_offer) {
      musts.push({
        match_phrase: {
          "other_data.on_offer": true,
        },
      });
    }

    if (params.other_top_offers) {
      musts.push({
        match_phrase: {
          "other_data.top_offers": true,
        },
      });
    }

    if (params.other_brand) {
      musts.push({
        match_phrase: {
          "other_data.company_name": params.other_brand,
        },
      });
    }

    if (params.other_grade) {
      musts.push({
        match_phrase: {
          "other_data.grade": params.other_grade,
        },
      });
    }

    if (params.on_variation_offer === true) {
      musts.push({
        exists: {
          field: "variations",
        },
      });
    } else if (params.on_variation_offer === false) {
      mustNots.push({
        exists: {
          field: "variations",
        },
      });
    }

    if (params.search_string) {
      shoulds.push({
        match_phrase: {
          name: params.search_string,
        },
      });
      shoulds.push({
        match_phrase_prefix: {
          name: {
            query: params.search_string,
          },
        },
      });

      shoulds.push({
        fuzzy: {
          name: {
            value: params.search_string,
            fuzziness: params.fuzziness ?? "AUTO",
            prefix_length: 2,
          },
        },
      });
      shoulds.push({
        match_phrase_prefix: {
          name: {
            query: params.search_string,
          },
        },
      });
    }

    if (params.category_id) {
      musts.push({
        match_phrase: {
          category_id: params.category_id,
        },
      });
    }

    if (params.category_ids) {
      let ids = params.category_ids;
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          shoulds.push({
            match_phrase: {
              category_id: ids[i],
            },
          });
        }
      }
    }

    if (params.ids) {
      let ids = params.ids;
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          shoulds.push({
            match_phrase: {
              id: ids[i],
            },
          });
        }
      }
    }

    if (params.grades && params.grades.length > 0) {
      var grades = params.grades;
      const gradeShoulds = [];
      for (var i = 0; i <= grades.length; i++) {
        if (grades[i]) {
          gradeShoulds.push({
            match_phrase: {
              "other_data.grade": grades[i],
            },
          });
        }
      }
      musts.push({
        bool: {
          should: gradeShoulds,
          minimum_should_match: 1,
        },
      });
    }

    if (params.brands && params.brands.length > 0) {
      const brands = params.brands;
      const brandShoulds = [];
      for (var i = 0; i <= brands.length; i++) {
        if (brands[i]) {
          brandShoulds.push({
            match_phrase: {
              "other_data.company_name": brands[i],
            },
          });
        }
      }
      musts.push({
        bool: {
          should: brandShoulds,
          minimum_should_match: 1,
        },
      });
    }

    if (params.sizes && params.sizes.length > 0) {
      const sizes = params.sizes;
      const sizeShoulds = [];
      for (var i = 0; i <= sizes.length; i++) {
        if (sizes[i]) {
          sizeShoulds.push({
            match_phrase: {
              "other_data.storage": sizes[i],
            },
          });
        }
      }
      musts.push({
        bool: {
          should: sizeShoulds,
          minimum_should_match: 1,
        },
      });
    }

    if (
      appConfig &&
      appConfig.search_config &&
      appConfig.search_config.length > 0
    ) {
      for (var i = 0; i <= appConfig.search_config.length; i++) {
        if (
          appConfig.search_config[i] &&
          appConfig.search_config[i].entity === "category_item"
        ) {
          musts.push({
            match_phrase_prefix: {
              [appConfig.search_config[i].attribute]:
                appConfig.search_config[i].value,
            },
          });
        }
      }
    }

    if (params.brand_name) {
      musts.push({
        match_phrase_prefix: {
          "other_data.brand_name": params.brand_name.replace("’", "'"),
        },
      });
    } else if (params.search_string) {
      shoulds.push({
        match_phrase_prefix: {
          "other_data.brand_name": {
            query: params.search_string,
            // fuzziness: params.fuzziness ?? "AUTO",
            //prefix_length: 2,
          },
        },
      });
    }

    let queryString = "";

    if (params.brand) {
      if (queryString != "") {
        queryString += " AND ";
      }
      queryString += "other_data.brand:" + '"' + params.brand + '"';
    }

    if (queryString) {
      queryParams = `&q=${queryString}`;
      url += queryParams;
    }

    if (params.distributor) {
      musts.push({
        match_phrase_prefix: {
          "other_data.distributor": params.distributor.replace("’", "'"),
        },
      });
    } else if (params.search_string) {
      shoulds.push({
        match_phrase_prefix: {
          "other_data.distributor": {
            query: params.search_string,
            // fuzziness: params.fuzziness ?? "AUTO",
            //prefix_length: 2,
          },
        },
      });
    }

    if (params.company_name) {
      musts.push({
        match_phrase_prefix: {
          // "other_data.distributor": params.company_name.replace("’", "'"),
          "other_data.company_name": params.company_name.replace("’", "'"),
        },
      });
    } else if (params.search_string) {
      shoulds.push({
        match_phrase_prefix: {
          "other_data.company_name": {
            query: params.search_string,
            // fuzziness: params.fuzziness ?? "AUTO",
            //prefix_length: 2,
          },
        },
      });
    }

    if (params.region) {
      musts.push({
        match_phrase_prefix: {
          "other_data.region": params.region.replace("’", "'"),
        },
      });
    } else if (params.search_string) {
      shoulds.push({
        match_phrase_prefix: {
          "other_data.region": {
            query: params.search_string,
          },
        },
      });
    }
    if (params.molecule) {
      musts.push({
        match_phrase_prefix: {
          "other_data.molecule": params.molecule.replace("’", "'"),
        },
      });
    } else if (params.search_string) {
      shoulds.push({
        match_phrase_prefix: {
          "other_data.molecule": {
            query: params.search_string,
            // fuzziness: params.fuzziness ?? "AUTO",
            //prefix_length: 2,
          },
        },
      });
    }

    if (params.therapy_area_simple) {
      musts.push({
        match_phrase_prefix: {
          "other_data.therapy_area_simple": params.therapy_area_simple.replace(
            "’",
            "'"
          ),
        },
      });
    } else if (params.search_string) {
      shoulds.push({
        match_phrase_prefix: {
          "other_data.therapy_area_simple": {
            query: params.search_string,
            // fuzziness: params.fuzziness ?? "AUTO",
            //prefix_length: 2,
          },
        },
      });
    }

    if (params.is_new) {
      musts.push({
        match_phrase_prefix: {
          "other_data.is_new": true,
        },
      });
    }

    if (params.bestsellers) {
      musts.push({
        match_phrase_prefix: {
          "other_data.bestsellers": true,
        },
      });
    }

    if (!_.isEmpty(params.refit_tags)) {
      _.forEach(params.refit_tags, (tag) => {
        if (!_.isEmpty(tag)) {
          musts.push({
            match_phrase: {
              [`other_data.${tag}`]: "yes",
            },
          });
        }
      });
    }

    let filters = [];
    if (params.price_range) {
      filters.push({
        range: {
          "price.base_price": {
            gte: params.price_range.gte,
            lte: params.price_range.lte,
            boost: 2,
          },
        },
      });
    }

    const postData = {
      query: {
        bool: {
          must: musts,
          must_not: mustNots,
          should: shoulds,
          minimum_should_match: shoulds.length >= 1 ? 1 : 0,
          filter: filters,
        },
      },
    };
    console.log("Search Query post data : ", JSON.stringify(postData, null, 2));
    console.log("Search Query post data url :- ", url);

    return $http.post(url, postData, { ...configHeader });
  }

  function searchCategoryItem(params) {
    const size = params.size || (params.ids || []).length || 25;
    const sizeParam = `&size=${size}`;
    const merchantId = params.merchant_id || PrestoSdk.getMerchantId();

    var appConfig = PrestoSdk.getAppConfig();
    var sortParam = "";
    if (params.price_low) {
      sortParam = "sort=price.base_price:asc";
    } else if (params.price_high) {
      sortParam = "sort=price.base_price:desc";
    } else if (params.name_asc) {
      sortParam = "sort=name.keyword:asc";
    } else if (params.name_desc) {
      sortParam = "sort=name.keyword:desc";
    } else if (params.sort_rating_desc) {
      sortParam = "sort=ratings_aggregate.avg_rating:desc";
    } else {
      sortParam = "sort=listing_order";
    }

    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_category_items/_search?" +
      sortParam +
      sizeParam;
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_category_items/_search?" +
        sortParam +
        sizeParam;
    }

    //override search url
    var baseQuery = "&q=";
    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url =
        getSearchHostName() +
        "/" +
        "category_items/_search?" +
        sortParam +
        sizeParam;
      if (params.merchant_id) {
        url =
          getSearchHostName() +
          "/" +
          "category_items/_search?" +
          sortParam +
          sizeParam;
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }

    if (params.min_price || params.max_price) {
      baseQuery += " AND price.base_price:>" + params.min_price;
      if (params.max_price) {
        baseQuery += " AND " + "price.base_price:<" + params.max_price;
      }
    }

    if (params.page) {
      let page;
      var from;
      if (params.page == 1) {
        from = params.page * 0;
      } else {
        page = params.page - 1;
        from = page * 25;
      }
      url += "&from=" + from;
    }

    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }

    var queryParams = "";
    if (params.merchant_ids) {
      url = "";
      url =
        getSearchHostName() +
        "/" +
        "category_items/_search?" +
        sortParam +
        sizeParam;
      if (params.page) {
        var from;
        var page;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        url += "&from=" + from;
      }
      var baseQuery = "&q=";
      url += baseQuery;
      if (params.merchant_ids.length > 0) {
        queryParams += "(";
        for (var i = 0; i <= params.merchant_ids.length; i++) {
          if (params.merchant_ids[i]) {
            if (i > 0) {
              queryParams += " OR ";
            }
            queryParams += "merchant_id:" + params.merchant_ids[i];
          }
        }
        queryParams += ")";
      }
    }

    if (params.category_ids && params.category_ids.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      var ids = params.category_ids;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "category_id:" + ids[i];
        }
      }
      queryParams += ")";
      // url += queryParams;
    }

    if (params.catalog_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "catalog_id:" + params.catalog_id;
    }

    if (params.variation_ids && params.variation_ids.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      var ids = params.variation_ids;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "variation.id:" + ids[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }

    if (params.search_string) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        " OR description:*" +
        params.search_string +
        "* OR keywords:*" +
        params.search_string +
        "* OR other_data.brand_name:*" +
        params.search_string +
        "* OR other_data.molecule:*" +
        params.search_string +
        "* OR other_data.therapy_area_simple:*" +
        params.search_string +
        "* OR other_data.company_name:*" +
        params.search_string +
        "*)";
    }
    if (params.medicine==0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.medicine:false";
    }
    if (params.on_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_offer:true";
    }
    if (params.top_offers) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.top_offers:true";
    }
    if (params.special_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.special_offer:true";
    }
    if (params.popular) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.popular:true";
    }
    if (params.vertical_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.vertical_offer:true";
    }
    if (params.outlet_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }
    if (params.id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "id:" + params.id;
    }

    if (params.ids && params.ids.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      var ids = params.ids;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "id:" + ids[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }
    if (params.seo_handle) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        '(seo_handle:"' +
        params.seo_handle +
        '" OR id:"' +
        params.seo_handle +
        '")';
    }
    if (params.category_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_id:" + params.category_id;
    }
    if (params.beauty_pack_reference_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "other_data.beauty_pack_reference_id:" +
        '"' +
        params.beauty_pack_reference_id +
        '"';
    }
    if (params.pkgid) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.pkgid:" + '"' + params.pkgid + '"';
    }

    if (params.brand_name) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.brand_name:" + '"' + params.brand_name + '"';
    }
    if (params.grade) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += `other_data.grade:"${params.grade}"`;
    }

    if (params.certified) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.certified:true";
    }

    if (params.refit_recommended) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.refit_recommended:true";
    }

    if (params.best_selling) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.best_selling:true";
    }

    if (params.grades && params.grades.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      var ids = params.grades;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += `other_data.grade:"${ids[i]}"`;
        }
      }
      queryParams += ")";
      url += queryParams;
    }

    if (params.brands && params.brands.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      var ids = params.brands;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data.company_name:" + ids[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }

    if (params.sizes && params.sizes.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      var ids = params.sizes;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data.storage:" + ids[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }

    if (params.company_name) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "other_data.company_name:" + '"' + params.company_name + '"';
    }
    if (params.region) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.region:" + '"' + params.region + '"';
    }
    if (params.molecule) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.molecule:" + '"' + params.molecule + '"';
    }
    if (params.therapy_area_simple) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "other_data.therapy_area_simple:" +
        '"' +
        params.therapy_area_simple +
        '"';
    }
    if (params.domid) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.domid:" + '"' + params.domid + '"';
    }
    if (params.resident_type) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.resident_type:" + params.resident_type;
    }
    if (params.credits) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.credits:" + params.credits;
    }
    if (params.ref_ids) {
      var ids = params.ref_ids;
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "reference_id:" + ids[i];
        }
      }
      queryParams += ")";
    }
    if (params.tag && params.tag.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= params.tag.length; i++) {
        if (params.tag[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data." + params.tag[i] + ":true";
        }
      }
      queryParams += ")";
    }
    if (params.tags && params.tags.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= params.tags.length; i++) {
        if (params.tags[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams +=
            "other_data.tags:" + encodeURIComponent(params.tags[i]);
        }
      }
      queryParams += ")";
    }

    if (params.mustTags && params.mustTags.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      let encodedTags = "";
      queryParams += "(";
      let lastIndexNumber = params.mustTags.length;
      for (var i = 0; i <= params.mustTags.length; i++) {
        if (params.mustTags[i]) {
          let andClause = "";
          if (lastIndexNumber !== i && i > 0) {
            encodedTags += " AND ";
          }
          encodedTags += encodeURIComponent(params.mustTags[i]);
        }
      }
      queryParams += "other_data.tags:" + encodedTags;
      queryParams += ")";
    }
    if (
      params.service_reference_ids &&
      params.service_reference_ids.length > 0
    ) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= params.service_reference_ids.length; i++) {
        if (params.service_reference_ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams +=
            "other_data.service_reference_ids:" +
            encodeURIComponent(params.service_reference_ids[i]);
        }
      }
      queryParams += ")";
    }
    if (queryParams != "") {
      queryParams += " AND ";
    }
    queryParams += "is_available:true";
    url += queryParams;
    var postData = {};
    if (params.brand_name) {
      var sortParam = "";
      if (params.price_low) {
        sortParam = "sort=price.base_price:asc";
      } else if (params.price_high) {
        sortParam = "sort=price.base_price:desc";
      } else if (params.name_asc) {
        sortParam = "sort=name.keyword:asc";
      } else if (params.name_desc) {
        sortParam = "sort=name.keyword:desc";
      } else if (params.sort_rating_desc) {
        sortParam = "sort=ratings_aggregate.avg_rating:desc";
      } else {
        sortParam = "sort=listing_order";
      }
      url = getSearchHostName() + "/" + "category_items/_search?";
      if (sortParam) {
        url += sortParam + sizeParam;
      } else {
        url += `size=${size}`;
      }
      if (params.page) {
        var from;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      postData.query = {
        bool: {
          must: {
            match_phrase_prefix: {
              "other_data.brand_name": params.brand_name.replace("’", "'"),
            },
          },
          must_not: {
            match_phrase: {
              "other_data.hide_in_search": "true",
            },
          },
        },
      };
    }
    if (params.company_name) {
      var sortParam = "";
      if (params.price_low) {
        sortParam = "sort=price.base_price:asc";
      } else if (params.price_high) {
        sortParam = "sort=price.base_price:desc";
      } else if (params.name_asc) {
        sortParam = "sort=name.keyword:asc";
      } else if (params.name_desc) {
        sortParam = "sort=name.keyword:desc";
      } else {
        sortParam = "sort=listing_order";
      }
      url = getSearchHostName() + "/" + "category_items/_search?";
      if (sortParam) {
        url += sortParam + sizeParam;
      } else {
        url += `size=${size}`;
      }
      if (params.page) {
        var from;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      postData.query = {
        bool: {
          must: [
            {
              match_phrase_prefix: {
                "other_data.company_name": params.company_name.replace(
                  "’",
                  "'"
                ),
              },
            },
            {
              match_phrase: {
                catalog_id: params.catalog_id,
              },
            },
          ],
          must_not: {
            match_phrase: {
              "other_data.hide_in_search": "true",
            },
          },
        },
      };
    }

    if (params?.ref_ids && params?.type == "combo") {
      let mustss = [];
      let refIds = params.ref_ids;
      const refIdsShoulds = [];
      for (var i = 0; i <= refIds.length; i++) {
        if (refIds[i]) {
          refIdsShoulds.push({
            match_phrase: {
              reference_id: refIds[i],
            },
          });
        }
      }
      mustss.push({
        bool: {
          should: refIdsShoulds,
          minimum_should_match: 1,
        },
      });
      postData = {
        query: {
          bool: {
            must: mustss,
          },
        },
      };
    }

    if (params.region) {
      var sortParam = "";
      if (params.price_low) {
        sortParam = "sort=price.base_price:asc";
      } else if (params.price_high) {
        sortParam = "sort=price.base_price:desc";
      } else if (params.name_asc) {
        sortParam = "sort=name.keyword:asc";
      } else if (params.name_desc) {
        sortParam = "sort=name.keyword:desc";
      } else {
        sortParam = "sort=listing_order";
      }
      url = getSearchHostName() + "/" + "category_items/_search?";
      if (sortParam) {
        url += sortParam + sizeParam;
      } else {
        url += `size=${size}`;
      }
      if (params.page) {
        var from;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      postData.query = {
        bool: {
          must: {
            match_phrase_prefix: {
              "other_data.region": params.region.replace("’", "'"),
            },
          },
          must_not: {
            match_phrase: {
              "other_data.hide_in_search": "true",
            },
          },
        },
      };
    }
    if (params.molecule) {
      var sortParam = "";
      if (params.price_low) {
        sortParam = "sort=price.base_price:asc";
      } else if (params.price_high) {
        sortParam = "sort=price.base_price:desc";
      } else if (params.name_asc) {
        sortParam = "sort=name.keyword:asc";
      } else if (params.name_desc) {
        sortParam = "sort=name.keyword:desc";
      } else {
        sortParam = "sort=listing_order";
      }
      url = getSearchHostName() + "/" + "category_items/_search?";
      if (sortParam) {
        url += sortParam + sizeParam;
      } else {
        url += `size=${size}`;
      }
      if (params.page) {
        var from;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      postData.query = {
        bool: {
          must: {
            match_phrase_prefix: {
              "other_data.molecule": params.molecule.replace("’", "'"),
            },
          },
          must_not: {
            match_phrase: {
              "other_data.hide_in_search": "true",
            },
          },
        },
      };
    }
    let musts = [];
    if (
      appConfig &&
      appConfig.search_config &&
      appConfig.search_config.length > 0
    ) {
      for (var i = 0; i <= appConfig.search_config.length; i++) {
        if (
          appConfig.search_config[i] &&
          appConfig.search_config[i].entity === "category_item"
        ) {
          musts.push({
            match_phrase_prefix: {
              [appConfig.search_config[i].attribute]:
                appConfig.search_config[i].value,
            },
          });
        }
      }
      postData.query = {
        bool: {
          must: musts,
          must_not: {
            match_phrase: {
              "other_data.hide_in_search": "true",
            },
          },
        },
      };
    }
    if (params.therapy_area_simple) {
      var sortParam = "";
      if (params.price_low) {
        sortParam = "sort=price.base_price:asc";
      } else if (params.price_high) {
        sortParam = "sort=price.base_price:desc";
      } else if (params.name_asc) {
        sortParam = "sort=name.keyword:asc";
      } else if (params.name_desc) {
        sortParam = "sort=name.keyword:desc";
      } else {
        sortParam = "sort=listing_order";
      }
      url = getSearchHostName() + "/" + "category_items/_search?";
      if (sortParam) {
        url += sortParam + sizeParam;
      } else {
        url += `size=${size}`;
      }
      if (params.page) {
        var from;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          let page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      postData.query = {
        bool: {
          must: {
            match_phrase_prefix: {
              "other_data.therapy_area_simple": params.therapy_area_simple.replace(
                "’",
                "'"
              ),
            },
          },
          must_not: {
            match_phrase: {
              "other_data.hide_in_search": "true",
            },
          },
        },
      };
    }

    if (params.search_by_name) {
      url += " AND (name:" + '"' + params.search_by_name + '"' + ")";
    }

    if (params.bulk_ids) {
      let obj = {
        terms: {
          id: params.bulk_ids,
        },
      };

      if (_.isEmpty(postData)) {
        postData = { query: { bool: { filter: {} } } };
      }

      postData.query.bool.filter = {
        ...postData.query.bool.filter,
        ...obj,
      };
    }

    if (params.is_available) {
      if (_.isEmpty(postData)) {
        postData = { query: { bool: {} } };
      }
      if (_.isEmpty(postData.query?.bool?.must)) {
        postData.query.bool.must = [];
      }

      let obj = {
        match_phrase: {
          is_available: true,
        },
      };

      postData.query.bool.must.push(obj);
    }

    if (params.post_merchant_id) {
      if (_.isEmpty(postData)) {
        postData = { query: { bool: {} } };
      }
      if (_.isEmpty(postData.query?.bool?.must)) {
        postData.query.bool.must = [];
      }

      let obj = {
        match: {
          merchant_id: params.post_merchant_id,
        },
      };

      postData.query.bool.must.push(obj);
    }

    if (params.price_range) {
      if (_.isEmpty(postData)) {
        postData = { query: { bool: {} } };
      }
      if (_.isEmpty(postData.query?.bool?.must)) {
        postData.query.bool.must = [];
      }
      if (_.isEmpty(postData.query?.bool?.should)) {
        postData.query.bool.should = [];
      }
      if (_.isEmpty(postData.query?.bool?.filter)) {
        postData.query.bool.filter = [];
      }

      let filters = [];
      filters.push({
        range: {
          "price.base_price": {
            gte: params.price_range.gte,
            lte: params.price_range.lte,
            boost: 2,
          },
        },
      });
      let mustMatch = [
        {
          match: {
            merchant_id: params.merchant_id,
          },
        },
      ];

      let shouldMatch = [];

      params.category_ids?.forEach((catID) => {
        postData.query.bool.should.push({
          match: {
            category_id: catID,
          },
        });

        if (params.brands && params.brands.length > 0) {
          var ids = params.brands;
          for (var i = 0; i <= ids.length; i++) {
            if (ids[i]) {
              shouldMatch.push({
                match_phrase: {
                  "other_data.company_name": ids[i],
                },
              });
            }
          }
        }
        if (!_.isEmpty(params.refit_tags)) {
          _.forEach(tags, (tag) => {
            mustMatch.push({
              [tag]: "Yes",
            });
          });
        }

        let nestedShould = [];
        params.category_ids?.forEach((catID) => {
          nestedShould.push({
            match: {
              category_id: catID,
            },
          });
        });
        mustMatch.push({
          bool: {
            should: nestedShould,
            minimum_should_match: 1,
          },
        });
      });

      if (params.brands && params.brands.length > 0) {
        var ids = params.brands;
        for (var i = 0; i <= ids.length; i++) {
          if (ids[i]) {
            shouldMatch.push({
              match_phrase: {
                "other_data.company_name": ids[i],
              },
            });
          }
        }
      }

      postData.query.bool.minimum_should_match = _.isEmpty(
        postData.query.bool.should
      )
        ? 0
        : 1;
      postData.query.bool.must.push(...mustMatch);
      postData.query.bool.should.push(...shouldMatch);
      postData.query.bool.filter.push(...filters);
    }

    if (!_.isEmpty(postData)) {
      if (
        appConfig &&
        appConfig.merchant_config &&
        appConfig.merchant_config.features.common_search
      ) {
        url = getSearchHostName() + "/" + "category_items/_search?";
      } else {
        url =
          getSearchHostName() + "/" + merchantId + "_category_items/_search?";
      }

      if (sortParam) {
        url += sortParam + sizeParam;
      } else {
        url += `size=${size}`;
      }
      if (params.page) {
        let page;
        var from;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        url += "&from=" + from;
      }
    }

    console.log("postdata---------", postData);
    console.log("url111---------", url);

    if (postData && postData.query) {
      return $http.post(url, postData, { ...configHeader });
    } else {
      return $http.get(url, {}, { ...configHeader });
    }
  }

  function searchBrand(params) {
    var appConfig = PrestoSdk.getAppConfig();
    console.log("Search Brand Params");
    console.log(params);
    var sortParam = "";
    if (params.price_low) {
      sortParam = "sort=price.base_price:asc";
    } else if (params.price_high) {
      sortParam = "sort=price.base_price:desc";
    } else if (params.name_asc) {
      sortParam = "sort=name.keyword:asc";
    } else if (params.name_desc) {
      sortParam = "sort=name.keyword:desc";
    } else {
      sortParam = "sort=listing_order";
    }
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_category_items/_search?" +
      sortParam +
      "&size=25";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_category_items/_search?" +
        sortParam +
        "&size=25";
    }

    //override search url
    var baseQuery = "&q=";
    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url =
        getSearchHostName() +
        "/" +
        "category_items/_search?" +
        sortParam +
        "&size=25";
      if (params.merchant_id) {
        url =
          getSearchHostName() +
          "/" +
          "category_items/_search?" +
          sortParam +
          "&size=25";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }
    if (params.page) {
      var page;
      var from;
      if (params.page == 1) {
        from = params.page * 0;
      } else {
        page = params.page - 1;
        from = page * 25;
      }
      url += "&from=" + from;
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }
    url += "is_available:true";

    if (params.brand) {
      url += " AND other_data.brand:" + params.brand;
    }
    if (params.brands && params.brands.length > 0) {
      var queryParams = " AND ";
      queryParams += "(";
      for (var i = 0; i <= params.brands.length; i++) {
        if (params.brands[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data.brand:" + params.brands[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }
    if (params.tag && params.tag.length > 0) {
      var queryParams = " AND ";
      queryParams += "(";
      for (var i = 0; i <= params.tag.length; i++) {
        if (params.tag[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data." + params.tag[i] + ":true";
        }
      }
      queryParams += ")";
      url += queryParams;
    }
    if (params.category_ids && params.category_ids.length > 0) {
      var queryParams = " AND ";
      var ids = params.category_ids;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "category_id:" + ids[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }
    if (params.category_id) {
      url += " AND category_id:" + params.category_id;
    }
    if (params.search_string) {
      url +=
        " AND (name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        " OR description:*" +
        params.search_string +
        "* OR keywords:*" +
        params.search_string +
        "*)";
    }
    if (params.outlet_id) {
      url +=
        " AND (outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }

    return $http.get(url, {}, { ...configHeader });
  }

  function getFilteredOtherDataAttributes(params, attributeName) {
    console.log("getFilteredOtherDataAttributes : ", params, attributeName);
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();

    let url =
      getSearchHostName() + "/" + merchantId + "_category_items/_search?";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "category_items/_search?";
    }

    var postData = {
      size: 0,
    };

    let musts = [
      {
        match_phrase: {
          is_available: true,
        },
      },
    ];

    if (params.catalog_id) {
      musts.push({
        match_phrase_prefix: {
          catalog_id: params.catalog_id,
        },
      });
    }

    if (merchantId) {
      musts.push({
        match_phrase_prefix: {
          merchant_id: merchantId,
        },
      });
    }

    if (
      appConfig &&
      appConfig.search_config &&
      appConfig.search_config.length > 0
    ) {
      for (var i = 0; i <= appConfig.search_config.length; i++) {
        if (
          appConfig.search_config[i] &&
          appConfig.search_config[i].entity === "category_item"
        ) {
          musts.push({
            match_phrase_prefix: {
              [appConfig.search_config[i].attribute]:
                appConfig.search_config[i].value,
            },
          });
        }
      }
    }

    postData.query = {
      bool: {
        must: musts,
      },
    };

    postData.aggs = {
      [`distinct_${attributeName}`]: {
        terms: {
          field:
            attributeName != "is_new"
              ? `other_data.${attributeName}.keyword`
              : `other_data.${attributeName}`,
          size: 10000,
        },
      },
    };

    console.log("Post data : ", url, JSON.stringify(postData));
    return $http.post(url, postData, { ...configHeader });
  }

  //  TODO (AMAL): revisit this Function why it is different.

  function getZipHealthFilteredOtherDataAttributes(params, attributeName) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_category_items/_search";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "category_items/_search";
    } else {
    }
    let musts = [
      {
        match_phrase: {
          is_available: true,
        },
      },
      {
        match_phrase: {
          merchant_id: merchantId,
        },
      },
    ];
    if (params.category_id) {
      musts.push({
        match_phrase: {
          category_id: params.category_id,
        },
      });
    }
    if (params.catalog_id) {
      musts.push({
        match_phrase: {
          catalog_id: params.catalog_id,
        },
      });
    }
    if (params.medicine == 0) {
      musts.push({
        match_phrase: {
          "other_data.medicine": "false",
        },
      });
    }
    if (
      appConfig &&
      appConfig.search_config &&
      appConfig.search_config.length > 0
    ) {
      for (var i = 0; i <= appConfig.search_config.length; i++) {
        if (
          appConfig.search_config[i] &&
          appConfig.search_config[i].entity === "category_item"
        ) {
          musts.push({
            match_phrase_prefix: {
              [appConfig.search_config[i].attribute]:
                appConfig.search_config[i].value,
            },
          });
        }
      }
    }
    var postData = {
      size: 0,
      query: {
        bool: {
          must: musts,
        },
      },
      aggs: {
        [`distinct_${attributeName}`]:
          attributeName != "is_new"
            ? {
                terms: {
                  field: `other_data.${attributeName}.keyword`,
                  size: 10000,
                },
              }
            : {
                filter: {
                  term: {
                    "other_data.is_new": true,
                  },
                },
              },
      },
    };
    console.log("params=======>",params);
    console.log("config============",configHeader);
    console.log("URLLL===>", url);
    console.log(
      "postData===>",
      attributeName === "is_new" && JSON.stringify(postData)
    );
    return $http.post(url, postData, { ...configHeader });
  }

  function getFilteredBrands(params) {
    var appConfig = PrestoSdk.getAppConfig();
    console.log("getFilteredBrands Params");
    console.log(params);
    var sortParam = "";
    if (params.price_low) {
      sortParam = "sort=price.base_price:asc";
    } else if (params.price_high) {
      sortParam = "sort=price.base_price:desc";
    } else if (params.name_asc) {
      sortParam = "sort=name.keyword:asc";
    } else if (params.name_desc) {
      sortParam = "sort=name.keyword:desc";
    } else {
      sortParam = "sort=listing_order";
    }
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_category_items/_search?" +
      sortParam +
      "&q=is_available:true";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_category_items/_search?" +
        sortParam +
        "&q=is_available:true";
    }

    //override search url
    if (appConfig && appConfig.merchant_config.features.common_search) {
      var baseQuery = "&q=";
      url =
        getSearchHostName() +
        "/" +
        "category_items/_search?" +
        sortParam +
        "&size=25";
      if (params.merchant_id) {
        url =
          getSearchHostName() +
          "/" +
          "category_items/_search?" +
          sortParam +
          "&size=25";
        baseQuery +=
          "merchant_id:" + params.merchant_id + " AND is_available:true";
      } else {
        baseQuery +=
          "merchant_id:" + PrestoSdk.getMerchantId() + " AND is_available:true";
      }
      url += baseQuery;
    }

    if (params.category_id) {
      url += " AND category_id:" + params.category_id;
    }
    if (params.category_ids && params.category_ids.length > 0) {
      var queryParams = " AND ";
      var ids = params.category_ids;
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "category_id:" + ids[i];
        }
      }
      queryParams += ")";
      url += queryParams;
    }
    if (params.tag && params.tag.length > 0) {
      var queryParams = " AND ";
      queryParams += "(";
      for (var i = 0; i <= params.tag.length; i++) {
        if (params.tag[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data." + params.tag[i] + ":true";
        }
      }
      queryParams += ")";
      url += queryParams;
    }
    if (params.search_string) {
      url +=
        " AND (name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        " OR description:*" +
        params.search_string +
        "* OR keywords:*" +
        params.search_string +
        "*)";
    }
    if (params.outlet_id) {
      url +=
        " AND (outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }
    var postData = {
      size: 0,
      aggs: {
        distinct_brands: {
          terms: {
            field: "other_data.brand.keyword",
            size: 10000,
          },
        },
      },
    };
    return $http.post(url, postData, { ...configHeader });
  }
  function searchCategory(params) {
    const appConfig = PrestoSdk.getAppConfig();
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_categories/_search?sort=listing_order&size=250&q=";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_categories/_search?sort=listing_order&size=250&q=";
    }

    //override search url
    var baseQuery = "&q=";
    if (appConfig && appConfig.merchant_config.features.common_search) {
      url =
        getSearchHostName() +
        "/" +
        "categories/_search?sort=listing_order&size=250";
      if (params.merchant_id) {
        url =
          getSearchHostName() +
          "/" +
          "categories/_search?sort=listing_order&size=250";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }
    var queryParams = "";
    if (params.search_string) {
      queryParams += "name:*" + params.search_string + "*";
    }
    if (params.on_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_offer:true";
    }
    if (params.all_channel) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.all_channel:true";
    }
    if (params.popular) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.popular:true";
    }
    if (params.show_sim_category) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.show_sim_category:true";
    }
    if (params.id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "id:" + params.id;
    }
    if (params.region) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "region:" + params.region;
    }
    if (params.seo_handle) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        '(seo_handle:"' +
        params.seo_handle +
        '" OR id:"' +
        params.seo_handle +
        '")';
    }
    if (params.category_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_id:" + params.category_id;
    }
    if (params.category_ids) {
      queryParams += "(";
      for (var i = 0; i <= params.category_ids.length; i++) {
        if (params.category_ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "id:" + params.category_ids[i];
        }
      }
      queryParams += ")";
    }
    if (params.catalog_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "catalog_id:" + params.catalog_id;
    }
    if (params.top_level) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.top_level:true";
    }
    if (params.top_level_service) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.top_level_service:true";
    }
    if (params.is_new) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.is_new:true";
    }
    // if (params.medicine==0) {
    //   if (queryParams != "") {
    //     queryParams += " AND ";
    //   }
    //   queryParams += "other_data.medicine:false";
    // }
    if (params.cable) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.cable:true";
    }
    if (params.broadband) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.broadband:true";
    }
    if (params.outlet_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "outlet_id:" + params.outlet_id;
    }
    if (params.tag && params.tag.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= params.tag.length; i++) {
        if (params.tag[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "other_data." + params.tag[i] + ":true";
        }
      }
      queryParams += ")";
    }
    if (queryParams != "") {
      queryParams += " AND ";
    }
    queryParams += "hidden:false";
    url += queryParams;
    return $http.get(url, {}, { ...configHeader });
  }
  function searchSellerItem(params) {
    var appConfig = PrestoSdk.getAppConfig();
    var sortParam = "";
    if (params.sort_enabled) {
      sortParam = "&sort=other_data.listing_order";
    } else if (params.price_low) {
      sortParam = "&sort=price.base_price:asc";
    } else if (params.price_high) {
      sortParam = "&sort=price.base_price:desc";
    }
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_seller_items/_search?size=25" +
      sortParam +
      "&q=";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_seller_items/_search?size=25" +
        sortParam +
        "&q=";
    }

    //override search url
    var baseQuery = "&q=";
    if (appConfig.merchant_config.features.common_search) {
      url = getSearchHostName() + "/" + "seller_items/_search?size=25";
      if (params.merchant_id) {
        url = getSearchHostName() + "/" + "seller_items/_search?size=25";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }
    var queryParams = "";
    if (params.search_string) {
      queryParams +=
        "(name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        ")";
    }
    if (params.category_item_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_item_id:" + params.category_item_id;
    }
    if (params.banner_product) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.banner_product:true";
    }
    if (params.on_sale) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_sale:true";
    }
    if (params.on_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_offer:true";
    }
    if (params.popular) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.popular:true";
    }
    if (params.resident_type) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.resident_type:" + params.resident_type;
    }
    if (params.sim_type) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.sim_type:" + params.sim_type;
    }
    if (params.min_price || params.max_price) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "price.base_price:>" +
        params.min_price +
        " AND " +
        "price.base_price:<" +
        params.max_price;
    }
    if (params.category_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_id:" + params.category_id;
    }
    if (params.categoryItem_ids) {
      var ids = params.categoryItem_ids;
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "category_item_id:" + ids[i];
        }
      }
      queryParams += ")";
    }
    if (params.seller_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "seller_id:" + params.seller_id;
    }
    if (params.variation_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "variations_added:" + params.variation_id;
    }
    if (params.outlet_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }
    if (params.catalog_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "catalog_id:" + params.catalog_id;
    }
    if (queryParams != "") {
      queryParams += " AND ";
    }
    queryParams += "is_available:true";
    url += queryParams;
    return $http.get(url, {}, { ...configHeader });
  }

  function searchService(params) {
    var appConfig = PrestoSdk.getAppConfig();
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_presto_services/_search?sort=listing_order&size=25";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_presto_services/_search?sort=listing_order&size=25";
    }

    //override search url
    var queryParams = "";
    var baseQuery = "&q=";
    if (appConfig.merchant_config.features.common_search) {
      url =
        getSearchHostName() +
        "/" +
        "presto_services/_search?sort=listing_order&size=25";
      if (params.merchant_id) {
        url =
          getSearchHostName() +
          "/" +
          "presto_services/_search?sort=listing_order&size=25";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }

    if (params.merchant_ids) {
      url = "";
      url =
        getSearchHostName() +
        "/" +
        "presto_services/_search?sort=listing_order&size=25";

      var baseQuery = "&q=";
      url += baseQuery;
      queryParams += "(";
      for (var i = 0; i <= params.merchant_ids.length; i++) {
        if (params.merchant_ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "merchant_id:" + params.merchant_ids[i];
        }
      }
      queryParams += ")";
    }

    if (params.page) {
      var from;
      var page;
      if (params.page == 1) {
        from = params.page * 0;
      } else {
        page = params.page - 1;
        from = page * 25;
      }
      url += "&from=" + from;
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }

    if (params.search_string) {
      queryParams +=
        "(name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        ")";
    }
    if (params.on_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_offer:true";
    }
    if (params.popular) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.popular:true";
    }
    if (params.is_new) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.is_new:true";
    }
    if (params.start_point && !params.end_point) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "is_available:true AND other_data.start_point.name:*" +
        params.start_point +
        "*";
    }
    if (params.end_point && params.start_point) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(is_available:true AND other_data.start_point.name:*" +
        params.start_point +
        "* AND other_data.end_point.name:*" +
        params.end_point +
        "*)";
    }
    if (params.outlet_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }
    if (params.id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "id:" + params.id;
    }
    if (params.seo_handle) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        '(seo_handle:"' +
        params.seo_handle +
        '" OR id:"' +
        params.seo_handle +
        '")';
    }
    if (params.category_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_id:" + params.category_id;
    }
    if (params.ref_ids) {
      var ids = params.ref_ids;
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "reference_id:" + ids[i];
        }
      }
      queryParams += ")";
    }
    if (params.tags && params.tags.length > 0) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= params.tags.length; i++) {
        if (params.tags[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams +=
            "other_data.tags:" + encodeURIComponent(params.tags[i]);
        }
      }
      queryParams += ")";
    }
    if (params.catalog_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "catalog_id:" + params.catalog_id;
    }
    url += queryParams;
    console.log("searchService URL ===>", url);
    return $http.get(url, {}, { ...configHeader });
  }

  function searchSellers(params) {
    var appConfig = PrestoSdk.getAppConfig();
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_sellers/_search?size=25";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_sellers/_search?size=25";
    }

    //override search url
    var baseQuery = "&q=";
    if (appConfig.merchant_config.features.common_search) {
      url = getSearchHostName() + "/" + "sellers/_search?size=25";
      if (params.merchant_id) {
        url = getSearchHostName() + "/" + "sellers/_search?size=25";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }
    if (params.page) {
      var page;
      var from;
      if (params.page == 1) {
        from = params.page * 0;
      } else {
        page = params.page - 1;
        from = page * 25;
      }
      url += "&from=" + from;
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }
    if (params.about) {
      url += "about:*" + params.about + "*";
    }
    if (params.id) {
      url += "id:" + params.id;
    }

    return $http.post(url, params.current_location, { ...configHeader });
  }

  function searchLocationBasedSellerServices(params) {
    var appConfig = PrestoSdk.getAppConfig();
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_services/_search?sort=listing_order&size=25";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_services/_search?sort=listing_order&size=25";
    }

    var baseQuery = "&q=";
    if (appConfig.merchant_config.features.common_search) {
      url =
        getSearchHostName() +
        "/" +
        "services/_search?sort=listing_order&size=25";
      if (params.merchant_id) {
        url =
          getSearchHostName() +
          "/" +
          "services/_search?sort=listing_order&size=25";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }

    if (params.page) {
      var from;
      var page;
      if (params.page == 1) {
        from = params.page * 0;
      } else {
        page = params.page - 1;
        from = page * 25;
      }
      url += "&from=" + from;
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }
    var queryParams = "";
    if (params.search_string) {
      queryParams +=
        "(name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        ")";
    }
    if (params.on_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_offer:true";
    }
    if (params.popular) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.popular:true";
    }
    if (params.is_new) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.is_new:true";
    }
    if (params.start_point && !params.end_point) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "is_available:true AND other_data.start_point.name:*" +
        params.start_point +
        "*";
    }
    if (params.end_point && params.start_point) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(is_available:true AND other_data.start_point.name:*" +
        params.start_point +
        "* AND other_data.end_point.name:*" +
        params.end_point +
        "*)";
    }
    if (params.outlet_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }
    if (params.id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "id:" + params.id;
    }
    if (params.seo_handle) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        '(seo_handle:"' +
        params.seo_handle +
        '" OR id:"' +
        params.seo_handle +
        '")';
    }
    if (params.category_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_id:" + params.category_id;
    }
    url += queryParams;
    return $http.post(url, params.current_location, { ...configHeader });
  }

  function searchLocationBasedSellerItems(params) {
    var appConfig = PrestoSdk.getAppConfig();
    var url =
      getSearchHostName() +
      "/" +
      PrestoSdk.getMerchantId() +
      "_seller_items/_search?size=25&q=";
    if (params.merchant_id) {
      url =
        getSearchHostName() +
        "/" +
        params.merchant_id +
        "_seller_items/_search?size=25&q=";
    }

    var baseQuery = "&q=";
    if (appConfig.merchant_config.features.common_search) {
      url = getSearchHostName() + "/" + "seller_items/_search?size=25";
      if (params.merchant_id) {
        url = getSearchHostName() + "/" + "seller_items/_search?size=25";
        baseQuery += "merchant_id:" + params.merchant_id;
      } else {
        baseQuery += "merchant_id:" + PrestoSdk.getMerchantId();
      }
    }
    url += baseQuery;
    if (baseQuery != "&q=") {
      url += " AND ";
    }
    var queryParams = "";
    if (params.search_string) {
      queryParams +=
        "(name:*" +
        params.search_string +
        "* OR reference_id:" +
        params.search_string +
        ")";
    }
    if (params.category_item_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_item_id:" + params.category_item_id;
    }
    if (params.resident_type) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.resident_type:" + params.resident_type;
    }
    if (params.on_offer) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.on_offer:true";
    }
    if (params.popular) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.popular:true";
    }
    if (params.sim_type) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "other_data.sim_type:" + params.sim_type;
    }
    if (params.min_price || params.max_price) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "price.base_price:>" +
        params.min_price +
        " AND " +
        "price.base_price:<" +
        params.max_price;
    }
    if (params.category_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "category_id:" + params.category_id;
    }
    if (params.categoryItem_ids) {
      var ids = params.categoryItem_ids;
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "category_item_id:" + ids[i];
        }
      }
      queryParams += ")";
    }
    if (params.seller_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "seller_id:" + params.seller_id;
    }
    if (params.variation_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "variations_added:" + params.variation_id;
    }
    if (params.outlet_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams +=
        "(outlet_id.$oid:" +
        params.outlet_id +
        " OR outlet_id:" +
        params.outlet_id +
        ")";
    }
    if (params.catalog_id) {
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "catalog_id:" + params.catalog_id;
    }
    if (queryParams != "") {
      queryParams += " AND ";
    }
    queryParams += "is_available:true";
    url += queryParams;
    return $http.post(url, params.current_location, { ...configHeader });
  }

  function searchLocationBasedMerchants(params) {
    var url = getSearchHostName() + "/" + getMerchantCommonIndex() + "/_search";
    var queryParams = "";
    var postData = {};
    if (params.id) {
      queryParams += "?q=id:" + params.id;
    } else if (params.merchant_ids) {
      queryParams += "?size=25&q=(!other_data.hide_in_search:true)";
      var ids = params.merchant_ids;
      if (queryParams != "") {
        queryParams += " AND ";
      }
      queryParams += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            queryParams += " OR ";
          }
          queryParams += "id:" + ids[i];
        }
      }
      queryParams += ")";
    } else if (params.search_string) {
      queryParams = "?size=25";
      if (params.page) {
        var from;
        var page;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      if (params.search_string) {
        postData.query = {
          bool: {
            must: {
              match_phrase_prefix: {
                name: params.search_string.replace("’", "'"),
              },
            },
            must_not: {
              match_phrase: {
                "other_data.hide_in_search": "true",
              },
            },
          },
        };
      }
    } else if (
      params.sub_category_type_strings ||
      params.category_type_strings ||
      params.tags ||
      params.delivery_types ||
      params.category
    ) {
      queryParams = "?size=25";
      var cTagArray = [];
      var cTags = params.category_type_strings || [];
      for (var i = 0; i <= cTags.length; i++) {
        if (cTags[i]) {
          cTagArray.push({
            match_phrase: { category: cTags[i] },
          });
        }
      }
      // console.log("cTagArray ======= ", JSON.stringify(cTagArray));
      var subTagArray = [];
      var subTags = params.sub_category_type_strings || [];
      for (var i = 0; i <= subTags.length; i++) {
        if (subTags[i]) {
          subTagArray.push({
            match_phrase: { "other_data.sub_category": subTags[i] },
          });
        }
      }
      // console.log("subTagArray ======= ", JSON.stringify(subTagArray));
      var tagArray = [];
      var tags = params.tags || [];
      for (var i = 0; i <= tags.length; i++) {
        if (tags[i]) {
          tagArray.push({
            match_phrase: { tags: tags[i] },
          });
        }
      }
      // console.log("tagArray ======= ", JSON.stringify(tagArray));
      var deliveryTypeArry = [];
      var deliveryTypes = params.delivery_types || [];
      for (var i = 0; i <= deliveryTypes.length; i++) {
        if (deliveryTypes[i] == "delivery") {
          deliveryTypeArry.push({
            match_phrase: { "other_data.delivery": true },
          });
        }
        if (deliveryTypes[i] == "pickup") {
          deliveryTypeArry.push({
            match_phrase: { "other_data.pickup": true },
          });
        }
        if (deliveryTypes[i] == "store") {
          deliveryTypeArry.push({
            match_phrase: { "other_data.dine_in": true },
          });
        }
      }
      var mIdArray = [];
      var mIds = params.mIds || [];
      for (var i = 0; i <= mIds.length; i++) {
        if (mIds[i]) {
          mIdArray.push({
            match_phrase: { id: mIds[i] },
          });
        }
      }
      // console.log("deliveryTypes ======= ", JSON.stringify(deliveryTypes));
      if (params.page) {
        var from;
        var page;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
      var finalArray = [];
      if (params.category) {
        deliveryTypeArry = deliveryTypeArry.concat({
          match_phrase: { category: params.category },
        });
      }
      if (tagArray.length > 0) {
        finalArray = finalArray.concat(tagArray);
      }
      if (cTagArray.length > 0) {
        finalArray = finalArray.concat(cTagArray);
      }
      if (subTagArray.length > 0) {
        finalArray = finalArray.concat(subTagArray);
      }
      if (mIdArray.length > 0) {
        finalArray = finalArray.concat(mIdArray);
      }
      // if(deliveryTypeArry.length > 0) {
      //   finalArray = finalArray.concat(deliveryTypeArry);
      // }
      console.log("finalArray ======= ", JSON.stringify(finalArray));
      postData.query = {
        bool: {
          should: finalArray.length > 0 ? finalArray : [],
          must: deliveryTypeArry.length > 0 ? deliveryTypeArry : [],
          minimum_should_match: finalArray.length > 0 ? 1 : 0,
        },
      };
      if (params.userLocation) {
        postData.query.bool.filter = {
          geo_distance: {
            distance: params.distance || "10000km",
            location: {
              lat: params?.userLocation[1],
              lon: params?.userLocation[0],
            },
          },
        };
      }
      if (params.sort_option) {
        var option = [];
        if (params.sort_option.value == "delivery_time") {
          option.push({ "other_data.delivery_time": "asc" });
        }
        if (params.sort_option.value == "rating") {
          option.push({ rating: "asc" });
        }
        postData.sort = option;
      }
    } else {
      queryParams += "?size=25&q=(!other_data.hide_in_search:true)";
      if (params.category_type_string) {
        queryParams +=
          " AND (category:'" +
          encodeURIComponent(params.category_type_string) +
          "' OR other_data.category :'" +
          encodeURIComponent(params.category_type_string) +
          "')";
      }
      if (params.sub_category_type_string) {
        queryParams +=
          " AND (other_data.sub_category :'" +
          encodeURIComponent(params.sub_category_type_string) +
          "')";
      }
      if (params.delivery) {
        queryParams += " AND (other_data.delivery:true)";
      }
      if (params.pickup) {
        queryParams += " AND (other_data.pickup:true)";
      }
      if (params.page) {
        var from;
        var page;
        if (params.page == 1) {
          from = params.page * 0;
        } else {
          page = params.page - 1;
          from = page * 25;
        }
        queryParams += "&from=" + from;
      }
    }
    url += queryParams;
    console.log("Final post data = ", url);
    console.log("postdata", JSON.stringify(postData));
    return $http.post(url, { ...params.current_location, ...postData });
  }

  function searchMerchantTickets(params) {
    var url = getSearchHostName() + "/" + "ticket_events/_search?";
    var queryParams = "size=25&q=";
    queryParams += "merchant_id:" + params.merchant_id;

    if (params.status) {
      queryParams += " AND (status:" + params.status + ")";
    }

    url += queryParams;
    return $http.get(url, {}, { ...configHeader });
  }

  function getFilteredMerchants(params) {
    console.log("getFilteredMerchants Params");
    console.log(params);
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() +
      "/" +
      merchantId +
      "_category_items/_search?q=is_available:true";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url =
        getSearchHostName() +
        "/" +
        "category_items/_search?q=is_available:true";

      url += "AND merchant_id:" + merchantId;
    }
    console.log("keyword url : ", url);
    //var url =
    //  getSearchHostName() + "/category_items/_search?q=is_available:true";
    var queryParams = "";
    if (params.search_string) {
      queryParams += " AND name:*" + params.search_string + "*";
    }
    url += queryParams;
    var postData = {
      size: 0,
      aggs: {
        distinct_merchants: {
          terms: {
            field: "merchant_id.keyword",
            size: 10000,
          },
        },
      },
    };
    return $http.post(url, postData, { ...configHeader });
  }

  function getReferenceIdBasedServices(params) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId =
      params.merchantId || params.merchant_id || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_presto_services/_search";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "presto_services/_search";
    }
    //let url = getSearchHostName() + "/presto_services/_search";
    if (params.size) {
      url += "?size=" + params.size;
    } else {
      url += "?size=" + 200;
    }
    var qs = "";
    if (params.category_id) {
      qs += "&q=category_id:" + params.category_id;
    }
    if (params.merchant_id) {
      if (qs) {
        qs += " AND merchant_id:" + params.merchant_id;
      } else {
        qs += "&q=merchant_id:" + params.merchant_id;
      }
    }
    if (params.search_string) {
      qs += " AND reference_id:" + params.search_string;
    }
    if (params.ids) {
      let refIds = params.ids;
      qs += "&q=reference_id:" + refIds.join(",");
    }
    if (params.service_ids) {
      let ids = params.service_ids;
      qs += "&q=";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            qs += " OR ";
          }
          qs += "id:" + ids[i];
        }
      }
    }
    url += qs;
    return $http.get(url, params, { ...configHeader });
  }

  function getServiceVariation(params) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_presto_services/_search";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "presto_services/_search";
    }
    var qs = "";
    if (params.reference_id) {
      qs += "?q=variations.reference_id:" + params.reference_id;
    }
    url += qs;
    return $http.get(url, params, { ...configHeader });
  }

  function getSponsoredSlot(params) {
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url = PrestoSdk.getHostName() + "/user/v1/sponsored_product_slots.json";
    return $http.get(url, params);
  }

  function getReferenceIdBasedProducts(params) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchant_id || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_category_items/_search";
    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "category_items/_search";
    }

    if (params.size) {
      url += "?size=" + params.size;
    } else {
      url += "?size=" + 200;
    }
    var queryParts = [];
    if (params.category_id) {
      queryParts.push("category_id:" + params.category_id);
    }
    if (params.merchant_id) {
      queryParts.push("merchant_id:" + params.merchant_id);
    }
    if (params.reference_id) {
      queryParts.push("reference_id:" + params.reference_id);
    }
    url += "&q=" + queryParts.join(" AND ");
    return $http.get(url, params, { ...configHeader });
  }

  function getAllServices(params) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_presto_services/_search";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "presto_services/_search";
    }
    if (params.size) {
      url += "?sort=listing_order&size=" + params.size;
    } else {
      url += "?sort=listing_order&size=" + 200;
    }
    var qs = "&q=merchant_id:" + params.merchantId;
    if (params.category_id) {
      qs += " AND category_id:" + params.category_id;
    }

    if (params.category_ids && params.category_ids.length > 0) {
      if (qs != "") {
        qs += " AND ";
      } else {
        qs += "&q=";
      }
      var ids = params.category_ids;
      qs += "(";
      for (var i = 0; i <= ids.length; i++) {
        if (ids[i]) {
          if (i > 0) {
            qs += " OR ";
          }
          qs += "category_id:" + ids[i];
        }
      }
      qs += ")";
      // url += queryParams;
    }
    if (params.is_available) {
      qs += " AND is_available:" + params.is_available;
    }
    if (params.merchant_id) {
      if (qs) {
        qs += " AND merchant_id:" + params.merchant_id;
      } else {
        qs += "&q=merchant_id:" + params.merchant_id;
      }
    }
    url += qs;
    if (params.search_string) {
      let shoulds = [];

      let musts = [
        {
          match_phrase: {
            is_available: true,
          },
        },
      ];
      if (!params.isExcludeMerchantId) {
        musts.push({
          match_phrase: {
            merchant_id: params.merchant_id || PrestoSdk.getMerchantId(),
          },
        });
      }

      shoulds.push({
        match_phrase: {
          name: params.search_string,
        },
      });
      shoulds.push({
        match_phrase_prefix: {
          name: {
            query: params.search_string,
          },
        },
      });

      shoulds.push({
        fuzzy: {
          name: {
            value: params.search_string,
            fuzziness: params.fuzziness ?? "AUTO",
            prefix_length: 2,
          },
        },
      });
      shoulds.push({
        match_phrase_prefix: {
          name: {
            query: params.search_string,
          },
        },
      });

      const postData = {
        query: {
          bool: {
            should: shoulds,
            must: musts,
            minimum_should_match: shoulds.length >= 1 ? 1 : 0,
          },
        },
      };
      console.log(
        "ganesh url :>> ",
        JSON.stringify(url),
        JSON.stringify(postData)
      );
      return $http.post(url, postData, { ...configHeader });
    }
    return $http.get(url, params, { ...configHeader });
  }

  function getAllServicesBasedOnSearchString(params) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_presto_services/_search";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "presto_services/_search";
    }
    if (params.size) {
      url += "?sort=listing_order&size=" + params.size;
    } else {
      url += "?sort=listing_order&size=" + 200;
    }
    var qs = "";
    if (params.category_id) {
      qs += "&q=category_id:" + params.category_id;
    }
    if (params.is_available) {
      qs += " AND is_available:" + params.is_available;
    }
    url += qs;
    if (params.search_string) {
      let shoulds = [];

      let musts = [
        {
          match_phrase: {
            is_available: true,
          },
        },
      ];
      if (!params.isExcludeMerchantId) {
        musts.push({
          match_phrase: {
            merchant_id: params.merchant_id || PrestoSdk.getMerchantId(),
          },
        });
      }

      if (params.catalog_id) {
        musts.push({
          match_phrase: {
            catalog_id: params.catalog_id,
          },
        });
      }

      shoulds.push({
        match_phrase: {
          name: params.search_string,
        },
      });
      shoulds.push({
        match_phrase_prefix: {
          name: {
            query: params.search_string,
          },
        },
      });

      shoulds.push({
        fuzzy: {
          name: {
            value: params.search_string,
            fuzziness: params.fuzziness ?? "AUTO",
            prefix_length: 2,
          },
        },
      });
      shoulds.push({
        match_phrase_prefix: {
          name: {
            query: params.search_string,
          },
        },
      });

      const postData = {
        query: {
          bool: {
            should: shoulds,
            must: musts,
            minimum_should_match: shoulds.length >= 1 ? 1 : 0,
          },
        },
      };
      console.log(
        "ganesh url :>> ",
        JSON.stringify(url),
        JSON.stringify(postData)
      );
      return $http.post(url, postData, { ...configHeader });
    }
    return $http.get(url, params, { ...configHeader });
  }

  function getServicesBasedOnTags(params) {
    const appConfig = PrestoSdk.getAppConfig();
    const merchantId = params.merchantId || PrestoSdk.getMerchantId();
    let url =
      getSearchHostName() + "/" + merchantId + "_presto_services/_search";

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + "/" + "presto_services/_search";
    }

    //let url = getSearchHostName() + "/presto_services/_search";
    if (config.features.use_old_service_endpoint) {
      url = getSearchHostName() + "/services/_search";
    }
    if (params.size) {
      url += "?size=" + params.size;
    } else {
      url += "?size=" + 200;
    }
    var qs = "";

    if (params.merchant_id) {
      if (qs) {
        qs += " AND merchant_id:" + params.merchant_id;
      } else {
        qs += "&q=merchant_id:" + params.merchant_id;
      }
    }
    url += qs;

    let musts = [];
    let shoulds = [];
    let ids = [];

    if (params.must) {
      _.forEach(params.must, (value, key) => {
        if (key == "tags") {
          let shouldtags = [];
          _.forEach(value, (value) => {
            shouldtags.push({
              match_phrase: {
                tags: value,
              },
            });
          });
          musts.push({
            bool: {
              should: shouldtags,
            },
          });
        }
      });

      if (params.other_tags) {
        _.forEach(params.other_tags, (value) => {
          shoulds.push({
            match_phrase: {
              tags: value,
            },
          });
        });
      }
      if (shoulds?.length) {
        musts.push({
          bool: {
            should: shoulds,
          },
        });
      }

      if (params.ids) {
        _.forEach(params.ids, (value) => {
          ids.push({
            match_phrase: {
              category_id: value,
            },
          });
        });
      }

      if (ids?.length) {
        musts.push({
          bool: {
            should: ids,
          },
        });
      }

      const postData = {
        query: {
          bool: {
            must: musts,
          },
        },
      };
      console.log(
        "Search Query post data : ",
        JSON.stringify(postData, null, 2)
      );
      console.log("Search Query post data url :- ", url);

      return $http.post(url, postData, { ...configHeader });
    }
    return $http.get(url, params, { ...configHeader });
  }

  function getCategoryItemsBasedOnQueries({
    shouldGroupedWithinMust = [],
    mustGrouped = [],
    range = {},
    sort = {
      key: "listing_order",
    },
    size = 200,
    page = null,
    minimumShouldMatch,
  }) {
    let appConfig = PrestoSdk.getAppConfig();
    let url = getSearchHostName() + `/category_items/_search?size=${size}`;
    if (page) {
      let from;
      if (page == 1) {
        from = page * 0;
      } else {
        page = page - 1;
        from = page * size;
      }
      url += "&from=" + from;
    }

    let hasMerchantIdKey = _.some(
      mustGrouped,
      (item) => item.key === "merchant_id"
    );
    let commonSearchEnabled = _.get(
      appConfig,
      "merchant_config.features.common_search",
      false
    );

    if (!hasMerchantIdKey && commonSearchEnabled) {
      mustGrouped.push({
        key: "merchant_id",
        values: PrestoSdk.getMerchantId(),
      });
    }

    let postData = {};

    const keyMappings = {
      tags: "other_data.tags",
      basePrice: "price.base_price",
      onOffer: "other_data.on_offer",
      avgRating: "ratings_aggregate.avg_rating",
    };

    if (
      _.some([shouldGroupedWithinMust, mustGrouped], (item) => !_.isEmpty(item))
    ) {
      postData = {
        query: {
          bool: {},
        },
      };
    }

    if (!_.isEmpty(shouldGroupedWithinMust)) {
      let mustQueries = [];
      _.forEach(shouldGroupedWithinMust, (item, index) => {
        const key = _.get(keyMappings, item.key, item.key);
        let queryType = _.get(item, "type", "match_phrase");
        let shouldQueries = [];

        if (queryType === "rawQuery") {
          shouldQueries = [...shouldQueries, ...item.values];
        } else if (_.isArray(item.values)) {
          shouldQueries = _.map(item.values, (shouldItem) => {
            return {
              [queryType]: {
                [key]: shouldItem,
              },
            };
          });
        } else if (_.isObject(item.values)) {
          shouldQueries.push({
            [queryType]: {
              [key]: item.values,
            },
          });
        }

        mustQueries.push({
          bool: {
            should: shouldQueries,
          },
        });
      });

      postData.query.bool = {
        must: mustQueries,
      };
    }

    if (!_.isEmpty(mustGrouped)) {
      let mustQueries = [];
      let nestedMustQueries = [];
      _.forEach(mustGrouped, (item, index) => {
        const key = _.get(keyMappings, item.key, item.key);
        let itemList = !Array.isArray(item.values)
          ? [item.values]
          : item.values;
        let queries = _.map(itemList, (mustItem) =>
          _.isEmpty(mustItem)
            ? undefined
            : { match_phrase: { [key]: mustItem } }
        );

        nestedMustQueries = _.concat(nestedMustQueries, queries);
      });

      mustQueries.push({
        bool: {
          must: nestedMustQueries,
        },
      });

      let prevMustQueries = _.get(postData, "query.bool.must", []);
      postData.query.bool = {
        must: [...prevMustQueries, ...mustQueries],
      };
    }

    if (!_.isEmpty(range)) {
      let prevMustQueries = _.get(postData, "query.bool.must", []);
      const key = _.get(keyMappings, range.key, range.key);
      let rangeObject = {
        range: {
          [key]: {
            gte: range.gte,
            lte: range.lte,
          },
        },
      };
      postData.query.bool = {
        must: [...prevMustQueries, rangeObject],
      };
    }

    if (!_.isEmpty(sort)) {
      const sortMappings = {
        price_low: {
          "price.base_price": {
            order: "asc",
          },
        },
        price_high: {
          "price.base_price": {
            order: "desc",
          },
        },
        sort_rating_desc: {
          "ratings_aggregate.avg_rating": {
            order: "desc",
          },
        },
      };

      const key = _.get(keyMappings, sort.key, sort.key);
      const sortObject = _.get(sortMappings, sort, null) ?? {
        [key]: {
          order: _.get(sort, "order", "asc"),
        },
      };
      let sortList = [sortObject];
      postData.sort = sortList;
    }

    if (minimumShouldMatch) {
      postData.query.bool.minimum_should_match = minimumShouldMatch;
    }
    return $http.post(url, postData, { ...configHeader });
  }

  function getMerchantInfo(params) {
    var url = `https://s3.ap-south-1.amazonaws.com/accounts.presto-apps.com/merchants/${params.id}.json`;
    return $http.get(url);
  }

  function strictSearch(params) {
    const size = params.size || 25;
    const page = params.page || 1;
    const merchantId = params.merchant_id || PrestoSdk.getMerchantId();
    const appConfig = PrestoSdk.getAppConfig();
    let url =
      getSearchHostName() +
      `/${merchantId}_category_items/_search?size=${size}`;

    if (
      appConfig &&
      appConfig.merchant_config &&
      appConfig.merchant_config.features.common_search
    ) {
      url = getSearchHostName() + `/category_items/_search?size=${size}`;
    }

    if (page) {
      let from;
      if (page == 1) {
        from = page * 0;
      } else {
        page = page - 1;
        from = page * size;
      }
      url += "&from=" + from;
    }

    let postData = {
      query: {
        bool: {
          must: [
            {
              match: {
                merchant_id: merchantId,
              },
            },
          ],
          should: [],
        },
      },
    };

    if (params.catalog_id) {
      postData.query.bool.must.push({
        match_phrase: {
          catalog_id: params.catalog_id,
        },
      });
    }

    if (params.is_available) {
      postData.query.bool.must.push({
        match_phrase: {
          is_available: params.is_available,
        },
      });
    }

    if (params.medicine == 0) {
      postData.query.bool.must.push({
        match_phrase: {
          "other_data.medicine": "false",
        },
      });
    }

    if (params.name) {
      postData.query.bool.should.push({
        match_phrase_prefix: {
          name: {
            query: params.name,
            boost: 100,
          },
        },
      });
    }

    if (params.brand_name) {
      postData.query.bool.should.push({
        match_phrase_prefix: {
          "other_data.brand_name": {
            query: params.brand_name,
            boost: 50,
          },
        },
      });
    }

    if (params.sponsored) {
      postData.query.bool.must.push({
        match_phrase: {
          "other_data.sponsored": {
            query: params.sponsored,
            boost: 50,
          },
        },
      });
    }
    if (params.sold_out) {
      postData.query.bool.must.push({
        match_phrase: {
          sold_out: {
            query: params.sold_out,
            boost: 50,
          },
        },
      });
    }

    if (params.molecule) {
      postData.query.bool.should.push({
        match_phrase_prefix: {
          "other_data.molecule": {
            query: params.molecule,
            boost: 25,
          },
        },
      });
    }
    if (
      !_.isEmpty(postData.query?.bool?.should) &&
      !_.has(postData.query?.bool?.minimum_should_match)
    ) {
      postData.query.bool.minimum_should_match = 1;
    }
    console.log("url2323===============",url);
    return $http.post(url, postData, { ...configHeader });
  }

  return {
    getAllServicesBasedOnSearchString: getAllServicesBasedOnSearchString,
    fuzzySearchCategoryItem: fuzzySearchCategoryItem,
    searchCategoryItem: searchCategoryItem,
    searchBrand: searchBrand,
    getFilteredBrands: getFilteredBrands,
    searchCategory: searchCategory,
    searchSellerItem: searchSellerItem,
    searchService: searchService,
    searchSellers: searchSellers,
    searchLocationBasedSellerItems: searchLocationBasedSellerItems,
    searchLocationBasedSellerServices: searchLocationBasedSellerServices,
    searchLocationBasedMerchants: searchLocationBasedMerchants,
    searchMerchantTickets: searchMerchantTickets,
    getFilteredMerchants: getFilteredMerchants,
    getFilteredOtherDataAttributes: getFilteredOtherDataAttributes,
    getAllServices: getAllServices,
    getReferenceIdBasedServices: getReferenceIdBasedServices,
    getReferenceIdBasedProducts: getReferenceIdBasedProducts,
    getServiceVariation: getServiceVariation,
    getServicesBasedOnTags: getServicesBasedOnTags,
    getCategoryItemsBasedOnQueries: getCategoryItemsBasedOnQueries,
    getZipHealthFilteredOtherDataAttributes: getZipHealthFilteredOtherDataAttributes,
    getMerchantInfo: getMerchantInfo,
    getSponsoredSlot: getSponsoredSlot,
    strictSearch,
  };
};

export default SearchResource(Utils, PrestoSdk, $http);

// https://search-prestostaging-xrrrt5jltpheeyvmkxzojrylvi.ap-south-1.es.amazonaws.com/category_items/_search?q=is_available%3Atrue+AND+name%3A*coke*

// https://search-prestostaging-xrrrt5jltpheeyvmkxzojrylvi.ap-south-1.es.amazonaws.com/category_items/_search?q=is_available:true AND name:*kol*
