import React, { useContext, useEffect, useCallback, useState } from "react";
import {
  View,
  SectionList,
  Dimensions,
  Platform,
  Pressable,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import OrderManager from "@presto-services/features/orders/OrderManager";
import LastOrderCard from "@presto-cards/LastOrderCard/LastOrderCard";
import _ from "lodash";
import { usePagination } from "../../hooks/appointmentPagination";
import PrestoText from "@presto-components/PrestoText";
import TrackOrderCard from "@presto-cards/TrackOrderCard/TrackOrderCard";
import TrackOrderModel from "@presto-component-models/TrackOrderModel";
import UserContext from "@presto-contexts/UserContext";
import PaymentManager from "@presto-services/features/payment/PaymentManager";
import AppConfig from "@presto-common/config";
import AnalyticsManager from "../../common/AnalyticsManager";
import config from "@presto-common/config";
import asyncify from "@presto-common/Asyncify";
import SearchManager from "@presto-services/features/search/SearchManager";
import { useCatalog } from "@presto-stores/CatalogStore";

const AsyncSearchManager = asyncify(SearchManager);

export default function OrdersListScreen() {
  const { theme } = useContext(ThemeContext);
  const { reOrder } = useContext(CartContext);
  const { Navigator } = useContext(NavigatorContext);
  const [isLoading, isSetLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { cart } = useContext(CartContext);
   const catalog = useCatalog((state) => state.catalog);
  const windowHeight = Dimensions.get("window").height;

  const loadOrders = useCallback((lastItem, success, failure) => {
    const lastTransaction = lastItem;
    const params = lastTransaction
      ? { oldest_time: `${lastTransaction.created_at}` }
      : {};
    OrderManager.getMyOrders(
      params,
      (response) => {
        let orders = response.data.orders;
        orders = _.filter(orders, (order) => order.type === "main_order");
        success(orders);
      },
      (error) => {
        failure(error);
      }
    );
  }, []);
  const [
    orders,
    loadNextPage,
    loadFirstPage,
    loading,
    pageLoaded,
  ] = usePagination(loadOrders);

  useEffect(() => {
    loadFirstPage();
  }, []);

  const onViewOrderClicked = (order) => {
    AnalyticsManager.send("past_order");
    Navigator.emit({
      event: "switchTab",
      params: {
        tabName: "ACCOUNT",
        pop: true,
      },
    });

    Navigator.emit({
      event: "push",
      params: {
        screenName: "ORDER_DETAILS_SCREEN",
        screenParams: {
          orderId: order.id,
          isInvoice: false,
        },
      },
    });
  };

  const onTrackOrderClicked = (order) => {
    AnalyticsManager.send("upcoming_order");
    Navigator.emit({
      event: "switchTab",
      params: {
        tabName: "ACCOUNT",
        pop: true,
      },
    });

    Navigator.emit({
      event: "modal",
      params: {
        screenName: "TRACK_ORDER_SCREEN",
        screenParams: {
          orderId: order.id,
        },
      },
    });
  };

  //TODO: Search for exact match for name in first api call
  const searchPart1 = (item) => {
    const params = {
      name: item,
      catalog_id: catalog?.id,
    };
    if (!config?.features?.show_all){
      params.is_available=true
    }
    if (user?.other_data?.medicine == 0) {
      params.medicine = user?.other_data?.medicine;
    }
      return AsyncSearchManager.strictSearch(params).then(
        ([error, response]) => {
          let items = _.get(response, "hits.hits", []);
          if(items[0]?._source?.sold_out){
            return items;
          }
          return [];
        }
      );
  };

  const filterSoldOut= async (items)=>{
    let result=[];
      for(let i=0;i<items?.length;i++){
        let res= await searchPart1(items[i]?.name);
        if(res?.length!==0){
          result.push(res[0]);
        }
      }
    return result;
  }

  const onReOrderClicked = async (order) => {
    AnalyticsManager.send("reorder");
    isSetLoading(true);
    let Items=order?.items;
    let soldItems=await filterSoldOut(Items);
    let filteredItems=[];
    Items.map((ele)=>{
      let found = soldItems?.find((item) => item?._id == ele?.item_id);
      if(!found){
        filteredItems?.push(ele);
      }
    });
    order.items = filteredItems;
    // console.log(JSON.stringify(order),"Order=====================");
    await reOrder(order);
    isSetLoading(false);
    Navigator.emit({
      event: "switchTab",
      params: {
        tabName: "CART",
      },
    });
  };

  const goToOnlinePayment = (order) => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "ONLINE_PAYMENT_SCREEN",
        screenParams: {
          order: order,
        },
      },
    });
  };

  const makeOnlinePayment = async (order, cart) => {
    isSetLoading(true);
    var params = {
      id_type: "order_id",
      id: cart.id,
      user: user,
      total_amount: cart.original_gross_amount,
      appypay: {
        transaction_type: "Order",
        transaction_id: cart?.id,
      },
    };

    const result = await PaymentManager.initiateAppyPayPayment(
      params,
      (resp) => {
        isSetLoading(false);
        goToOnlinePayment(cart);
      },
      (error) => {
        isSetLoading(false);
        alert(I18n.t("screen_messages.appypay_error"));
      }
    );
  };

  const renderSectionHeader = ({ section }) => {
    if (!section.title || section.title === "" || section.data?.length === 0) {
      return null;
    }
    if (section.key == "delivered_orders" && section.data.length == 0) {
      return null;
    }
    return (
      <View
        style={{
          paddingHorizontal: theme.primaryPadding,
          marginTop: 15,
          height: 20,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <PrestoText fontStyle="semibold" color={theme.primary} size={16}>
          {section.title || ""}
        </PrestoText>
      </View>
    );
  };

  const deliveredOrders = _.slice(orders, 1);
  const undeliveredOrders = orders.length > 0 ? [_.first(orders)] : [];

  const sections = [
    {
      title: "",
      key: "undelivered_orders",
      data: undeliveredOrders,
    },
    {
      title: I18n.t("screen_messages.orders.past_orders"),
      key: "delivered_orders",
      data: deliveredOrders,
    },
  ];

  const renderOrder = ({ item, section }) => {
    let items = item.items;
    if (item.line_items) {
      items = _.map(_.keys(item.line_items), (k) => item.line_items[k]);
    }
    const filteredItems = _.filter(
      items,
      (item) => item?.reference_id !== "DELIVERY_CHARGE"
    );
    const count = _.sumBy(filteredItems, (i) => i.quantity);
    const hasGiftItem = _.some(items, (e) => e?.other_data?.is_gift == "true");

    if (
      item &&
      !_.includes(["DELIVERED", "CLOSED"], item.status) &&
      ((item.payment_status !== "SUCCESS" || item.payment_status !== "PARTIAL_PAYMENT") || hasGiftItem)
    ) {
      return (
        <View style={{ width: "100%" }}>
          <Pressable
            onPress={() => {
              onTrackOrderClicked(item);
            }}
          >
            <View
              style={{
                paddingHorizontal: theme.containerPadding,
                paddingVertical: theme.containerPadding - 5,
              }}
            >
              <TrackOrderCard
                order={new TrackOrderModel({ order: item, count: count })}
                onPayPressed={() => makeOnlinePayment(cart, item)}
                onTrackOrderPressed={() => onTrackOrderClicked(item)}
                enablePayButton={AppConfig?.features?.enable_pay_now}
              />
            </View>
          </Pressable>
        </View>
      );
    } else {
      return (
        <View style={{ width: "100%" }}>
          <Pressable
            onPress={() => {
              onViewOrderClicked(item);
            }}
          >
            <View
              style={{
                paddingHorizontal: theme.containerPadding,
                paddingVertical: theme.containerPadding - 5,
              }}
            >
              <LastOrderCard
                onReOrderPressed={() => onReOrderClicked(item)}
                onPayPressed={() => makeOnlinePayment(cart, item)}
                order={new TrackOrderModel({ order: item, count: count })}
                onViewOrderPressed={() => onViewOrderClicked(item)}
              />
            </View>
          </Pressable>
        </View>
      );
    }
  };

  const onEndReached = () => {
    loadNextPage();
  };

  return (
    <LoadingContainer loading={(loading && pageLoaded == 0) || isLoading}>
      <SafeAreaView
        style={{
          flex: 1,
          paddingBottom: 60,
        }}
      >
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.orders_str")}
        />
        <SectionList
          keyExtractor={(_, index) => "index-" + index}
          stickySectionHeadersEnabled={false}
          renderSectionHeader={renderSectionHeader}
          sections={sections}
          renderItem={renderOrder}
          layout={"default"}
          showsHorizontalScrollIndicator={false}
          onEndReached={onEndReached}
          contentContainerStyle={{ paddingBottom: 150 }}
          style={{ height: windowHeight - 150 }}
        />
      </SafeAreaView>
    </LoadingContainer>
  );
}
