import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import $ from "../../global/util";

const ProfileResource = function (PrestoSdk, $http) {
  function getProfile(user) {
    if (user) {
      var url = PrestoSdk.getHostName() + "/users/" + user.id + ".json";
    } else {
      var url = PrestoSdk.getHostName() + "/me.json";
    }
    return $http.get(url);
  }

  function updateProfile(user) {
    var url = PrestoSdk.getHostName() + "/users/" + user.id + ".json";
    return $http.put(url, user);
  }

  function updateProfileDocument(user) {
    console.log("updateProfile resource call", user);
    var url =
      PrestoSdk.getHostName() +
      "/marketplace/user/v1/users/update_profile.json";
    return $http.put(url, user);
  }

  function deleteAddress(params) {
    var url =
      PrestoSdk.getHostName() +
      "/users/" +
      params.userId +
      "/addresses/" +
      params.addressId +
      ".json";
    return $http.delete(url);
  }

  function updateAddress(params) {
    var url =
      PrestoSdk.getHostName() +
      "/users/" +
      params.userId +
      "/addresses/" +
      params.addressId +
      ".json";
    return $http.put(url, params);
  }

  function getMyProfile() {
    var url = PrestoSdk.getHostName() + "/my_profile.json";
    return $http.get(url);
  }

  function addAdditionalData(params, successCallback, errorCallback) {
    var user = params.user;
    delete params["user"];
    var url = PrestoSdk.getHostName() + "/users/" + user.id + "/profile.json";
    return $http.put(url, params);
  }

  function getAdditionalData(params, successCallback, errorCallback) {
    var user = params.user;
    delete params["user"];
    var url = PrestoSdk.getHostName() + "/users/" + user.id + "/profile.json";
    return $http.get(url);
  }

  function getUserBonusCoupon(successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/coupons/my_bonus_coupons.json";
    return $http.get(url);
  }

  function getActiveSubscription(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/broadband/inventum/accounts/current_status.json?inventum_account_id=" +
      params.id;
    return $http.get(url);
  }

  function getAvailableAccounts(successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/broadband/inventum/accounts/available_accounts.json";
    return $http.get(url);
  }

  function getCustomerInfo(params, successCallback, errorCallback) {
    var url =
      PrestoSdk.getHostName() +
      "/cc/" +
      params.merchant_id +
      "/users/" +
      params.phone +
      ".json?id_type=phone";
    return $http.get(url);
  }

  function addAddress(params, successCallback, errorCallback) {
    console.log("addAddress resource call");
    var url =
      PrestoSdk.getHostName() + "/users/" + params.user_id + "/addresses.json";
    return $http.post(url, params);
  }

  function virtualAccountDetail(params) {
    var url = PrestoSdk.getHostName() + "/user/v1/virtual_account_detail.json";
    return $http.get(url);
  }

  function deleteUserAccount() {
    var url = PrestoSdk.getHostName() + "/user/v1/delete_my_account.json";
    return $http.post(url);
  }

  return {
    updateAddress: updateAddress,
    updateProfile: updateProfile,
    getProfile: getProfile,
    deleteAddress: deleteAddress,
    addAdditionalData: addAdditionalData,
    getAdditionalData: getAdditionalData,
    getUserBonusCoupon: getUserBonusCoupon,
    getActiveSubscription: getActiveSubscription,
    getAvailableAccounts: getAvailableAccounts,
    getCustomerInfo: getCustomerInfo,
    addAddress: addAddress,
    virtualAccountDetail: virtualAccountDetail,
    deleteUserAccount: deleteUserAccount,
    updateProfileDocument: updateProfileDocument,
    getMyProfile: getMyProfile,
  };
};

export default ProfileResource(PrestoSdk, $http);
