import React, { useContext, useState } from "react";
import { View, TouchableOpacity, Dimensions } from "react-native";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";
import * as ImagePicker from "expo-image-picker";
import * as Permission from "expo-permissions";
import ThemeContext from "@presto-contexts/ThemeContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import { useAssets } from "presto-react-components";
import ImageUploadIcon from "@presto-screen-components/ImageUpload/ImageUploadIcon";
import MediaLibrary from "@presto-screen-components/MediaLibrary/MediaLibrary";
import I18n from "i18n-js";

export default function ImageUploadButton(props) {
  const { theme } = useContext(ThemeContext);
  const [showOptionsForMedia, setShowOptionsForMedia] = useState(false);
  const { getAllImages, imageText, showOption = false } = props || {};

  const onPressButton = () => {
    setShowOptionsForMedia(true);
  };

  const mediaCallback = (response) => {
    const items = !_.isEmpty(response) ? [response] : [];
    getAllImages(items);
  };
  return (
    <>
      <View style={{ paddingVertical: 20 }}>
        <TouchableOpacity onPress={onPressButton} activeOpacity={0.7}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                // flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.uploadButton,
                width: "100%",
                // height: 50,
                borderRadius: 8,
              }}
            >
              <ImageUploadIcon color={theme.placeHolderColor} />
              <View style={{ paddingTop: 5 }}>
                <PrestoText
                  fontStyle="400.semibold"
                  size={16}
                  color={theme.placeHolderColor || "#707070"}
                >
                  {imageText || I18n.t("screen_messages.common.add_photo")}
                </PrestoText>
              </View>
            </View>
          </View>
        </TouchableOpacity>

        {/* <VerticalSpacing size={10} /> */}
        <MediaLibrary
          cameraQuality={0.2}
          showOptions={showOptionsForMedia}
          showOption={showOption}
          onShowOptions={setShowOptionsForMedia}
          callback={mediaCallback}
          fileIndex={1}
        />
      </View>
    </>
  );
}
