import React, { useContext, useReducer, useEffect } from "react";
import { View, SectionList, ScrollView } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import WebItemCard from "@presto-screen-components/ItemCard/WebItemCard";
import { usePagination } from "../../hooks/pagination";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import _ from "lodash";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import AsyncStorage from "@react-native-community/async-storage";
import PrestoText from "@presto-components/PrestoText";
import { useCatalog } from "@presto-stores/CatalogStore";
import asyncify from "@presto-common/Asyncify";
import config from "@presto-common/config";
import UserContext from "@presto-contexts/UserContext";

const AsyncSearchManager = asyncify(SearchManager);

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{ query: payload, auto_complete_results: [] },
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
        ...payload,
      };
    case "SET_SORT_TYPE":
      return {
        ...state,
        sort_type: payload,
      };
    case "SET_SEARCH_RESULTS":
      return { ...state, search_results: payload, auto_complete_results: [] };
    case "SET_RECENT_SEARCHES":
      return { ...state, recent_searches: payload };

    case "SET_AUTO_COMPLETE_RESULTS":
      return { ...state, auto_complete_results: _.slice(payload, 0, 7) };
    case "SET_SEARCH_OBJECT":
      return { ...payload };
  }
  return state;
}

export default function SearchScreen({
  navigation,
  searchObj,
  showDeals,
  showNoResultText,
  catalogId = null,
  useMultiSearch = false,
}) {
     const searchParams = searchObj || {};
     const catalog = useCatalog((state) => state.catalog);
     const { theme } = useContext(ThemeContext);
     const { Navigator } = useContext(NavigatorContext);
     const { user } = useContext(UserContext);
     const [state, dispatch] = useReducer(reducer, {
       query: searchParams.query,
       category_id: searchParams.category?.id,
       category_name: searchParams.category?.name,
       company_name: searchParams.company_name,
       distributor: searchParams?.distributor,
       brand_name: searchParams.brand_name,
       molecule: searchParams.molecule,
       therapy_area_simple: searchParams.therapy_area_simple,
       sort_type: "listing",
       on_offer: undefined,
       auto_complete_results: [],
       is_new: searchParams.is_new || false,
       bestsellers: searchParams.bestsellers || false,
       price_range: searchParams.price_range || null,
       region: searchParams.region || null,
     });

     useEffect(() => {
       dispatch({
         type: "SET_SEARCH_OBJECT",
         payload: searchObj,
       });
     }, [searchObj]);

     const isSearchEmpty = () => {
       return (
         _.isEmpty(state.query) &&
         _.isEmpty(state.category_id) &&
         _.isEmpty(state.company_name) &&
         _.isEmpty(state.distributor) &&
         _.isEmpty(state.brand_name) &&
         _.isEmpty(state.molecule) &&
         _.isEmpty(state.therapy_area_simple) &&
         _.isEmpty(state.region) &&
         !state.is_new &&
         !state.bestsellers
       );
     };

     //TODO: Search for exact match for name in first api call
     const searchPart1 = () => {
       const params = {
         name: state.query,
         catalog_id: catalogId || catalog?.id,
         brand_name: state.brand_name || state.query,
         molecule: state.molecule || state.query,
       };
        if (!config?.features?.show_all) {
          params.is_available = true;
        }
        if (user?.other_data?.medicine == 0) {
          params.medicine = user?.other_data?.medicine;
        }
       return AsyncSearchManager.strictSearch(params).then(
         ([error, response]) => {
           let items = _.get(response, "hits.hits", []);
           return items;
         }
       );
     };

     //TODO: Search for exact match for name in first api call
     const searchPart4 = () => {
       const params = {
         name: state.query,
         catalog_id: catalogId || catalog?.id,
         sponsored: "yes",
         sold_out: "false",
         brand_name: state.brand_name || state.query,
         molecule: state.molecule || state.query,
       };
       if (!config?.features?.show_all) {
         params.is_available = true;
       }
        if (user?.other_data?.medicine == 0) {
          params.medicine = user?.other_data?.medicine;
        }
       return AsyncSearchManager.strictSearch(params).then(
         ([error, response]) => {
           let items = _.get(response, "hits.hits", []);
           return items;
         }
       );
     };

     //TODO: Search for fuzzy match for molecule or any other fields in third api call
     const searchPart3 = (pageNumber = 1) => {
       let params = {
         category_id: state.category_id,
         search_string: state.query,
         brand_name: state.brand_name,
         company_name: state.company_name,
         distributor: state?.distributor,
         molecule: state.molecule,
         therapy_area_simple: state.therapy_area_simple,
         on_variation_offer: state.on_offer,
         [state.sort_type]: true,
         page: pageNumber,
         is_new: state.is_new,
         bestsellers: state.bestsellers,
         price_range: state.price_range,
         catalog_id: catalogId || catalog?.id,
         region: state.region,
         size: 1000,
       };
        if (!config?.features?.show_all) {
          params.is_available = true;
        }

       if (_.isEmpty(state.sort_type)) {
         params.name_asc = true;
       }
        if (user?.other_data?.medicine == 0) {
          params.medicine = user?.other_data?.medicine;
        }

       return AsyncSearchManager.fuzzySearchCategoryItem(params).then(
         ([error, response]) => {
           let items = _.get(response, "hits.hits", []);
           return items;
         }
       );
     };

     const liftReleventQueryStartingWith = (items, query) => {
       items.sort((obj1, obj2) => {
         let hasStartingQuery = _.startsWith(
           _.lowerCase(obj1._source.name),
           _.lowerCase(query)
         );

         if (hasStartingQuery) {
           return -1;
         }

         return 1;
       });

       return items;
     };

     const searchPart5 = () => {
       const params = {};
       return AsyncSearchManager.getSponsoredSlot(params).then(
         ([error, response]) => {
           return response;
         }
       );
     };

     const mergeSponsoredItem = (items, sponsored, slots) => {
       let pos = slots[0]?.placements;
      //  let pos=[1,4,5,7];
       if (!pos) {
         return items;
       }
       let mergedArray = [];
       let j = 0;
       let i = 0;
       let c = 0;
       let total = items.length + sponsored.length;
       if (pos) {
         while (c < total) {
           let found = pos.find((ele) => ele?.position - 1 === c);
           if (found) {
             if (j < sponsored.length) {
               mergedArray.push(sponsored[j]);
               j++;
             } else {
               mergedArray.push(items[i]);
               i++;
             }
           } else {
             if (i < items.length) {
               mergedArray.push(items[i]);
               i++;
             } else {
               mergedArray.push(sponsored[j]);
               j++;
             }
           }
           c++;
         }
       }
       return mergedArray;
     };

     const filterSoldOut = (item, sponsoredItems, slots) => {
       let soldOutItem = item.filter((ele) => {
         return config?.features?.show_all
           ? ele?._source?.is_available === false
           : ele?._source?.sold_out === true;
       });
       let unSoldOutItem = item.filter((ele) => {
         return config?.features?.show_all
           ? ele?._source?.is_available === true
           : ele?._source?.sold_out === false
       });
       let items = mergeSponsoredItem(unSoldOutItem, sponsoredItems, slots);
       return [...items, ...soldOutItem];
     };

     const mergedSearch = (pageNumber) => {
       let promises = [
         state.query ? searchPart1() : [],
         state.query ? searchPart4() : [],
         searchPart3(pageNumber),
         searchPart5(),
       ];
       return Promise.all(promises).then(([items1, items2, items3, slots]) => {
         let sponsoredItems = items2;
         let items = _.uniqBy(
           _.compact(_.concat(items1, items3)),
           (e) => e._source.id
         );
        //  items=_.uniqBy([...items,...sponsoredItems], function (e) {
        //    return e._source.id;
        //  });
         items = _.differenceBy(items, sponsoredItems, (e) => e._source.id);
         items = liftReleventQueryStartingWith(items, state.query);
         items = filterSoldOut(items, sponsoredItems, slots);
         return items;
       });
     };

     const searchPage = (pageNumber, success, failure) => {
       if (useMultiSearch) {
         mergedSearch(pageNumber).then((items) => {
           success(items);
         });
       } else {
         searchPart3(pageNumber).then((items) => {
          items = filterSoldOut(items, [], []);
          items = _.uniqBy(items, (e) => e._source.id);
          success(items);
         });
       }
     };

     const [
       results,
       loadNextPage,
       loadFirstPage,
       loading,
       pageLoaded,
     ] = usePagination(searchPage);

     useEffect(() => {
       loadFirstPage();
     }, [
       state.query,
       state.sort_type,
       state.company_name,
       state.on_offer,
       state.price_range,
       state.category_id,
       state.bestsellers,
       state.molecule,
       state.therapy_area_simple,
       state.is_new,
       state.price_range,
       state.region,
       state.distributor,
       state.brand_name
     ]);

     const renderItem = ({ item, key }) => {
       return (
         <View key={key} style={{ marginHorizontal: 20, marginVertical: 20 }}>
           <WebItemCard
             key={item._source.id}
             item={item._source}
             priceAccessorMethod={(product) => {
               return product.formatCurrency2;
             }}
           />
         </View>
       );
     };

     const onSortChanged = (key) => {
       dispatch({
         type: "SET_SORT_TYPE",
         payload: key,
       });
     };

     const onFilterChanged = (params) => {
       dispatch({
         type: "SET_SEARCH_FILTER",
         payload: params,
       });
     };

     const renderSectionHeader = ({ section }) => {
       if (
         !section.title ||
         section.title === "" ||
         section.data?.length === 0
       ) {
         return null;
       }
       return (
         <View
           style={{
             paddingHorizontal: theme.primaryPadding,
             marginTop: 15,
             height: 40,
             flexDirection: "row",
             alignItems: "flex-end",
           }}
         >
           <PrestoText fontStyle="medium" color={theme.paragraph}>
             {section.title || ""}
           </PrestoText>
         </View>
       );
     };

     const onSearchItemPress = (query) => {
       onSearch(_.toLower(query));
     };

     const onSearch = async (text) => {
       dispatch({ type: "SET_SEARCH_QUERY", payload: text });
       const recentSearchesJson = await AsyncStorage.getItem("RECENT_SEARCHES");
       const recentSearches = recentSearchesJson
         ? JSON.parse(recentSearchesJson)
         : [];
       const filteredSearches = _.filter(recentSearches, (s) => s !== text);
       const newRecentSearches = [text, ...filteredSearches];
       const splicedSearches = _.slice(newRecentSearches, 0, 6);
       await AsyncStorage.setItem(
         "RECENT_SEARCHES",
         JSON.stringify(splicedSearches)
       );
     };

     useEffect(() => {
       async function fet() {
         const recentSearchesJson = await AsyncStorage.getItem(
           "RECENT_SEARCHES"
         );
         dispatch({
           type: "SET_RECENT_SEARCHES",
           payload: recentSearchesJson ? JSON.parse(recentSearchesJson) : [],
         });
       }
       fet();
     }, []);

     const renderSearchItem = ({ item: query, index }) => {
       return (
         <View key={`index-${index}-query`}>
           <RowButton
             title={query}
             rightTitle={""}
             onPress={() => {
               onSearchItemPress(query);
             }}
             showDisclosure={true}
           />
           <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
         </View>
       );
     };

     const renderEmptySearchPage = () => {
       const sections = [
         {
           title: I18n.t("screen_messages.common.recent_searches"),
           key: "recent_searches",
           data: state.recent_searches || [],
         },
         {
           title: I18n.t("screen_messages.common.popular_searches"),
           key: "popular_searches",
           data: ["Paracetamol", "Ibucap", "Headache"],
         },
       ];
       return (
         <SectionList
           keyExtractor={(_, index) => "index-" + index}
           stickySectionHeadersEnabled={false}
           renderSectionHeader={renderSectionHeader}
           sections={sections}
           renderItem={renderSearchItem}
           layout={"default"}
           showsHorizontalScrollIndicator={false}
         />
       );
     };

     const RenderHeader = (props) => {
       return (
         <View>
           {results.length > 0 ? (
             <View
               style={{
                 paddingHorizontal: theme.containerPadding,
                 flexDirection: "row",
                 marginTop: 20,
                 marginBottom: 10,
               }}
             >
               <PrestoText fontStyle="medium" color={theme.paragraph} size={18}>
                 {searchResultsTitle
                   ? I18n.t("screen_messages.products.products_in")
                   : I18n.t(
                       "screen_messages.common.search_results_default_prefix"
                     )}
               </PrestoText>
               <PrestoText
                 fontStyle="700.semibold"
                 color={theme.paragraph}
                 size={18}
                 extraStyle={{
                   paddingLeft: 5,
                 }}
               >
                 {searchResultsTitle}
               </PrestoText>
             </View>
           ) : results.length == 0 &&
             !loading &&
             pageLoaded > 0 &&
             showNoResultText ? (
             <View style={{ paddingHorizontal: theme.containerPadding }}>
               <PrestoText
                 fontStyle="semibold"
                 size={theme.h5FontSize}
                 color={theme.primary}
               >
                 {searchResultsTitle
                   ? I18n.t("screen_messages.common.no_search_results_for", {
                       query: searchResultsTitle,
                     })
                   : I18n.t(
                       "screen_messages.common.no_search_results_default_prefix"
                     )}
               </PrestoText>
             </View>
           ) : null}
         </View>
       );
     };

     let searchResultsTitle =
       state.query ||
       state.company_name ||
       state.brand_name ||
       state.molecule ||
       state.therapy_area_simple ||
       state.category_name ||
       state.distributor ||
       "";

     if (state.is_new) {
       searchResultsTitle = "New on Zip";
     } else if (state.bestsellers) {
       searchResultsTitle = "Best sellers";
     }

     const isCloseToBottom = ({
       layoutMeasurement,
       contentOffset,
       contentSize,
     }) => {
       const paddingToBottom = 20;
       return (
         layoutMeasurement.height + contentOffset.y >=
         contentSize.height - paddingToBottom
       );
     };

     if (isSearchEmpty()) {
       return renderEmptySearchPage();
     } else
       return (
         <View style={{ margin: 10, flex: 1 }}>
           {/* <ScrollView
          data={results}
          style={{ height: 100 }}
          contentContainerStyle={{
            paddingHorizontal: theme.containerPadding,
            paddingBottom: 100,
          }}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              loadNextPage();
            }
          }}
          scrollEventThrottle={400}
        > */}
           <View
             style={{ width: "100%", flexWrap: 1, flexDirection: "column" }}
           >
             <View style={{ height: !showDeals ? 0 : 100 }}>
               {!showDeals ? null : <DealsComponent />}
             </View>
             <RenderHeader />
             <View
               style={{
                 width: "100%",
                 height: "100%",
                 flexWrap: "wrap",
                 flexDirection: "row",
                 alignItems: "flex-start",
                 alignContent: "flex-start",
               }}
             >
               {_.map(results, (item, index) => {
                 return renderItem({ item, index, key: item._source.id });
               })}
             </View>
             <VerticalSpacing size={20} />
           </View>
           {/* </ScrollView> */}
         </View>
       );
   }
