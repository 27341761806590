import React, { useContext, useReducer, useEffect } from "react";
import { View, ScrollView } from "react-native";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import SearchManager from "@presto-services/features/search/SearchManager";
import UserContext from "@presto-contexts/UserContext";

import _ from "lodash";
export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_TOP_CATEGORIES":
      return { ...state, top_level_categories: payload };

    case "SET_CATEGORY_COUNT":
      return {
        ...state,
        category_count: {
          ...state.category_count,
          ...{ [payload.cat_id]: payload.count },
        },
      };
  }
  return state;
}

export default function WebSubCategories({ category, onNavigate }) {
  const { theme } = useContext(ThemeContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [state, dispatch] = useReducer(reducer, { category_count: {} });
  const { user } = useContext(UserContext);

  useEffect(() => {
    SearchManager.searchCategory(
      { category_id: category?.id },
      (response) => {
        dispatch({
          type: "SET_TOP_CATEGORIES",
          payload: response.hits.hits,
        });
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    _.forEach(state.top_level_categories, (cat) => {
        let params = {
         category_id: cat._source.id,
        };
       if (user?.other_data?.medicine == 0) {
         params.medicine = user?.other_data?.medicine;
       }
      SearchManager.searchCategoryItem(
        params,
        (response) => {
          console.log(
            "Categories cat : ",
            cat._source.name,
            JSON.stringify(response)
          );
          dispatch({
            type: "SET_CATEGORY_COUNT",
            payload: { cat_id: cat._source.id, count: response.hits.total },
          });
        },
        (error) => {}
      );
    });
  }, [state.top_level_categories]);

  const onCategoryPress = (cat) => {
    console.log("Cat Id : ", cat);
    SearchManager.searchCategory(
      { category_id: cat._id },
      (response) => {
        if (response.hits.hits.length === 0) {
          onNavigate({ category_id: cat._source.id });
        } else {
          WebNavigator.emit({
            event: "push",
            params: {
              screenName: "SUB_CATEGORIES_BROWSE_SCREEN",
              screenParams: {
                category: cat._source,
                searchParams: {
                  category: cat._source,
                },
              },
            },
          });
        }
      },
      (error) => {
        console.log("Error : ", error);
      }
    );
  };
  return (
    <ScrollView style={{ maxHeight: 600 }}>
      <>
        {_.map(state.top_level_categories, (cat) => {
          return (
            <View key={cat._id}>
              {state.category_count[cat._source.id] > 0 ? (
                <RowButton
                  title={cat._source.name}
                  rightTitle={state.category_count[cat._source.id] ?? ""}
                  onPress={() => {
                    onCategoryPress(cat);
                  }}
                  showDisclosure={true}
                  numberOfLines={2}
                />
              ) : null}
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            </View>
          );
        })}
      </>
    </ScrollView>
  );
}
