import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function RadioButtonCheck(props) {
  return (
    <Svg
      width="18px"
      height="18px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G stroke="#707070" strokeWidth={3} fill="none" fillRule="evenodd">
        <Circle cx={16} cy={16} r={13.25} />
        <Path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M8 17L13.3333333 22 24 12"
        />
      </G>
    </Svg>
  );
}

export default RadioButtonCheck;
